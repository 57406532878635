import * as FaIcons from "react-icons/fa";
import { translate } from "../../../../utils/Common";
import GroupFormCreate from "./GroupFormCreate";
import { useState } from "react";
import { GiFloatingPlatforms, GiPlatform } from "react-icons/gi";
import { MdEditSquare, MdModeEditOutline } from "react-icons/md";


const CreatePlatform = () => {

  const [modulesIsCompleted, setModulesIsCompleted] = useState(false);
   const [settingsLocker, setSettingsLocker] = useState({});
   const queryParams = new URLSearchParams(window.location.search);
   const typeView = queryParams.get('view');

  return (
      <>
      <div className="dashboardCard mb-1 text-left">

        <div style={{ borderBottom: "1px solid #D3D3D3", paddingBottom: "15px", marginBottom: "15px", display:"flex", justifyContent: "space-between", alignItems: "center" }}>
           <div className="text-left d-flex" style={{ alignItems: "center", gap: "10px" }}>
                     <a href="/dashboard/platforms/general" >
                       <h5 className="link-hover-effect mb-0" >
                        <GiPlatform className="mr-2" size={25} />
                        {translate("global.platforms", "capital")}
                       </h5>
                     </a>
                     <div className='headerSeparator'> &gt; </div>
         
                   {
                     typeView==="create" ? (
                       <span className="ml-1 mt-1 text-bold">
                       <FaIcons.FaPlusCircle className="mr-1" />
                        Create Setting
                     </span>
                     ) : (
                      <span className="ml-1 mt-1 text-bold"><MdEditSquare className="mr-1"/> Edit Setting</span>
                     )
                   }

          

          {
                     Object.keys(settingsLocker).length > 0 && (
                      <>
                      <div className='headerSeparator'> &gt; </div>
                       <span className="ml-1 mt-1">
                       {settingsLocker?.namePlatform}
                     </span>
                      </>
                     )
                   }
        </div>
        
       
        {/* <p className="mx-0 mb-0 mt-1">
          {translate("dashboard.platforms.create.description")}
        </p> */}
        {
          modulesIsCompleted && (
            <div className="alert alert-success mt-2" role="alert" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap:"10px" }}>
              <div>
               <FaIcons.FaCheck /> {translate("global.allModulesComplete")}.
              </div>
              <div>
                <button className="btn btn-gray" onClick={() => window.location.href = '/dashboard/platforms/general'}>{translate('global.close')}</button>
              </div>
            </div>
          )
        }
        </div>
        
        <GroupFormCreate setModulesIsCompleted={setModulesIsCompleted} settingsLocker={settingsLocker} setSettingsLocker={setSettingsLocker}/>
      </div>


      </>
    
  );
};

export default CreatePlatform;
