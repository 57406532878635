import { useCallback } from "react";
import { getClientDomain, getToken } from "../utils/Common";

export const usePackages = () => {
    
    const getPackages = useCallback(async (data) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/packages/find/openLocker` , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(data),
           
        });
        
        const result = await response.json();
        if (result?.payload?.length > 0) return (result.payload);
        else return ([]);
    }, [])

    const createPackage = useCallback(async (data, signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/packages/register/individual`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(data),
                signal 
        });
        
        const result = await response.json();
        
        return (result);
    }, [])
 
    const getEstatusAlerted = useCallback(async (signal) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/packages/estatus/alerted`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            signal
        });

        const result = await response.json();
         
        return (result);
    }, [])
  
    const  getPackagesAlerted = useCallback(async (dataToSend) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/packages/find/tracking/alerted`, {
            method: 'POST',
            connection: 'keep-alive',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(dataToSend)
          
        });

        const result = await response.json();
        
        return (result);
    }, [])

    const deleteAlerted = useCallback(async (data) => {
        const response = await fetch(`${getClientDomain(2)}/dashboard/packages/cancel/alerted`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(data)
        });

        const result = await response.json();
        
        return (result);
    }, []);

    const syncPackageToLocker = useCallback(async (data) => {
        const response = await fetch(`${getClientDomain(2)}/packages/jwt/sync`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(data)
        });

        const result = await response.json();
        if (result) return (result);
        else return ([]);
        }, []);

    return {getPackages, createPackage, getEstatusAlerted, getPackagesAlerted, deleteAlerted, syncPackageToLocker};
}