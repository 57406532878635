import React, { useEffect, useState } from 'react';
import { FaArrowCircleRight, FaEdit, FaSave, FaTools } from 'react-icons/fa';
import './index.css';
import { getConditionValue, hoursIn24And12Format, translate } from '../../../../utils/Common';
import { useForm } from 'react-hook-form/dist/index.ie11';
import Skeleton from '../../../../components/ui/Skeleton';
import { usePlatforms } from '../../../../hooks/usePlatforms';
import Spinner from '../../../../components/Spinner';
import ModalContent from '../../../../components/ModalContent';
import BtnSync from '../../../../components/BtnSync';

const FormSettingsLocker = (props) => {
  const {dataDetails, setOptionSelect, isLoadingData, externalId, setExternalId, typeView, setDataDetails } = props;

  const {createSettingsPlatform, updateSettingsPlatform} = usePlatforms();
  const { register, errors, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({ title: '', body: '', footer: '' });
  const [showModal, setShowModal] = useState(false);
    const [originalData, setOriginalData] = useState(null);
    const [editMode, setEditMode] = useState(true);

  const formFields = [
    { id: 'locationId', label: translate("dashboard.platforms.support.openLockerCard.locationId"), required: true, colSize: 'col-6', valueDefault: dataDetails?.externalId, disabled: true },
  ];

  const selectFields = [
    { id: 'screenId', label: translate("global.screenResolution"), required: true, options: [{id: 1, value:'Square'}, {id: 2, value:'Portrait'}], colSize: 'col-6', valueDefault: dataDetails?.resolutionScreen, disabled: (dataDetails?.resolutionScreen && editMode) },
    { id: 'timeTurnLampOn', label: translate("global.turnLampOnHour"), required: true, options: hoursIn24And12Format, colSize: 'col-6', valueDefault: dataDetails?.TurnOn, isHidden: dataDetails?.IdTypeLocker === 2 ? false : true, disabled: dataDetails?.TurnOn && editMode },
    { id: 'timeTurnLampOff', label: translate("global.turnLampOffHour"), required: true, options: hoursIn24And12Format, colSize: 'col-6', valueDefault: dataDetails?.TurnOff, isHidden: dataDetails?.IdTypeLocker === 2 ? false : true, disabled: dataDetails?.TurnOff && editMode },
    { id: 'checkItem', label: translate("global.checkItem"), required: true, options: [{id: true, value: translate("global.enabled")}, {id: false, value: translate("global.disabled")}], colSize: 'col-6', valueDefault: dataDetails?.checkItem, disabled:  (getConditionValue(dataDetails?.checkItem) && editMode) },
  ];

  const renderInputField = (props) => {
    const { id, label, required, colSize = "col-12", valueDefault = "",  } = props;

    return (
      <div className={colSize}>
        {isLoadingData ? <div className='mt-1 mb-2'><Skeleton width={100} height={10} /></div> : <label className="text-label-title" htmlFor={id}>
            {label} {required && <span className='text-danger'>*</span>}
          </label>}
        <div className="form-group mb-2">
          {isLoadingData ? <div className='mt-2 mb-3'><Skeleton width='100%' height={40} /></div> : (
            <input
            id={id}
            name={id}
            defaultValue={valueDefault}
            disabled={valueDefault} 
            ref={register({
              required: required && {
                value: true,
                message: translate('global.isRequerid'),
              },
            })}
            className={`form-control form-control-md${errors[id] ? ' mb-2 is-invalid' : ''}`}
          />
          )}
          
          {errors[id] && <div className="invalid-feedback">{errors[id].message}</div>}
        </div> 
      </div>
    );
  };
  
  const renderSelectField = (props) => {
    const { id, label, required, options, colSize = "col-12", valueDefault = "", isHidden, disabled} = props;

    return (
      <div className={colSize}>
      <div className="form-group mb-2">
        {
          !isHidden && (
            <>
{isLoadingData ? (
          <div className="mt-1 mb-2">
            <Skeleton width={100} height={10} />
          </div>
        ) : (
          <label className="text-label-title" htmlFor={id}>
            {label} {required && <span className="text-danger">*</span>}
          </label>
        )}
        <div className="mb-3">
          {isLoadingData ? (
            <div className="mt-2 mb-3">
              <Skeleton width="100%" height={40} />
            </div>
          ) : (
            <select
              id={id}
              name={id}
              defaultValue={valueDefault}
              disabled={disabled}              
              ref={register({
                required: required && {
                  value: true,
                  message: translate('global.isRequerid'),
                },
              })}
              className={`form-control form-control-md${errors[id] ? ' mb-2 is-invalid' : ''}`}
            >
              <option value="">Seleccione...</option>
              {options.map((option, index) => (
                <option key={index} value={option.id ?? index}>
                  {option?.value}
                </option>
              ))}
            </select>
          )}
        </div>
        {errors[id] && <div className="invalid-feedback">{errors[id].message}</div>}
            </>
          )
        }
        
      </div>
    </div>);
  };

  const submitCreateSettingsPlatform = async (data) => { 
    setIsLoading(true);
    const abortController = new AbortController();
  
    const dataToSend = {
      ...data,
      screenId: parseInt(data.screenId),
      locationId: dataDetails?.externalId,
      splashTimer: 90000,
      supportNumber: "718-374-6835",
      supportEmail: "support@golocker.com", 
      boxValidationTimer: 30000, 
      checkItem: data.checkItem==="true" ? true : false,
      currentTemperature: 28,
      tempUpperLimit: 30,
      tempLowerLimit: 27, 
      fanStatus: false,
    }

    const response = await createSettingsPlatform(dataToSend, abortController.signal);
    
    setExternalId(null)
    if (response.code === 200) {
      if (response?.boxitSettingId > 0) {
          setExternalId(externalId);
          setOptionSelect('modules');
      }
    } else {
  setModal({
        title: translate( "global.alert.attention"),
        body: translate("dashboard.packages.details.dropOff.receivePackage.NOT_FOUND"),
        footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
      });
      setShowModal(true);
    }
    setIsLoading(false);
  }
  
  const submitUpdateSettingsPlatform = async (data) => {
    setIsLoading(true);
    const abortController = new AbortController();
  
    const dataToSend = {
      ...data,
      screenId: parseInt(data.screenId),
      locationId: dataDetails?.externalId,
      splashTimer: 90000,
      supportNumber: "718-374-6835",
      supportEmail: "support@golocker.com", 
      boxValidationTimer: 30000, 
      checkItem: data.checkItem==="true" ? true : false,
      currentTemperature: 28,
      tempUpperLimit: 30,
      tempLowerLimit: 27, 
      fanStatus: false,
    }

    const response = await updateSettingsPlatform(dataToSend, abortController.signal);

    if (response.code === 200) {
       setModal({
              title: translate( "global.alert.ready"),
              body: translate("global.updateSuccessSettingsPlatform"),
              footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
            });
            setShowModal(true);
            setEditMode(true);
            setOriginalData(data); 

            // Actualiza los DATOS CON LOS DATOS ANTERIORES
            setDataDetails((prevData) => ({
              ...prevData,
              resolutionScreen: Number(dataToSend.screenId),
              TurnOn: dataToSend.timeTurnLampOn,
              TurnOff: dataToSend.timeTurnLampOff,
            }));


    } else {
  setModal({
        title: translate( "global.alert.attention"),
        body: translate("dashboard.packages.details.dropOff.receivePackage.NOT_FOUND"),
        footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
      });
      setShowModal(true);
    }
    setIsLoading(false);

  }

    useEffect(() => {
      if (typeView === 'edit' && dataDetails) {
        setEditMode(true);
        setOriginalData(dataDetails); // Guarda los datos originales
        reset(dataDetails); // Establece los valores iniciales en el formulario
        return;
      }
      if (typeView === 'create') {
        setEditMode(false);
        return;
      }
  
    }, [typeView]);

  return (
    <form className='cardInternal' onSubmit={handleSubmit(dataDetails?.BoxitSettingId ? submitUpdateSettingsPlatform : submitCreateSettingsPlatform)}>
      <div className="cardInternal-header">
        <div className='cardInternal-title'>
          <span className="mr-1"><FaTools /></span>
          {translate("global.configuration")}
        </div>
        <div className="cardInternal-actions" style={{display:"flex",alignItems:"center", gap:'15px'}}>
        {dataDetails?.BoxitSettingId > 0 && <BtnSync mode="settings" settingId={dataDetails?.BoxitSettingId}/> }
          {
            !isLoadingData && (
              dataDetails && dataDetails?.BoxitSettingId ? (
                <div className="d-flex align-items-center" style={{gap:'10px'}}>
 {
                editMode ? (
              <button className="btn btn-gray btn-sm" type='button' onClick={() => setEditMode((prevMode) => !prevMode)}>
                <FaEdit/> {translate("global.edit")} 
              </button>
                ):(
                  <>
                    <button className="btn btn-light btn-sm" type='button' onClick={() => {
                      reset(originalData);
                      setEditMode((prevMode) => !prevMode);
                      }} >
                     {translate("global.cancel")} 
                  </button>
                  <button className="btn btn-blue btn-sm" type='submit' disabled={isLoading}>
                   {!isLoading ? <FaSave /> : <Spinner type={1} />} {translate("global.save")} 
                  </button>
                  </>
                )
              }

{editMode && <button className="btn btn-gray btn-sm" onClick={() => setOptionSelect('modules')}>
                {translate("login.signup.btn.next")} <FaArrowCircleRight />
              </button>}
                </div>
                              
              ) : (
                <button className="btn btn-blue btn-sm" disabled={isLoading} type="submit">
                <span className='mr-1'>{isLoading ? (<Spinner type={20} />) : (<FaSave />)}</span> {translate("global.save")}
              </button>
              )
            ) 
          }
        </div>
      </div>
      <div className="cardInternal-body">
        <div className="row">
          {formFields.map((field) => renderInputField(field))}
          {selectFields.map((field) => renderSelectField(field))}
        </div>
      </div>
      <ModalContent
                        title={modal.title}
                        body={modal.body}
                        footer={modal.footer}
                        isOpenModal={showModal}
                        onHide={() => setShowModal(false)}
                />
    </form>
  );
};

export default FormSettingsLocker;
