/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-assign */
import React, { useEffect, useState } from "react";
import { isBoolean } from "lodash";
import { FaArrowDown, FaArrowUp, FaDesktop, FaDoorOpen, FaEdit, FaHome, FaInfoCircle, FaLock, FaSave } from "react-icons/fa";
import { getConditionValue, translate } from "../../../../utils/Common";
import { useForm } from "react-hook-form/dist/index.ie11";
import { MdLaptopChromebook } from "react-icons/md";
import { usePlatforms } from "../../../../hooks/usePlatforms";
import ModalContent from "../../../../components/ModalContent";
import Spinner from "../../../../components/Spinner";
import BtnSync from '../../../../components/BtnSync';

const FormDoorsLocker = (props) => {
  const {createDoorsPlatforms, updateDoorPlatform} = usePlatforms();
  const { selectDoor, setConfigLocker, configLocker,handleGetInfoPlatform, setIsLoadingDoor, setSelectDoor, externalId, setDoorsInBd, setEditModeDoors, doorsInBd  } = props;
    const { register, errors, handleSubmit, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({ title: '', body: '', footer: '' });
  const [showModal, setShowModal] = useState(false);
  const [originalConfigLocker, setOriginalConfigLocker] = useState(null);
  const [originalSelectDoor, setOriginalSelectDoor] = useState(null);
  const [openModeEdit, setOpenModeEdit] = useState(false);
  const [openModalCompleteModule, setOpenModalCompleteModule] = useState(false);

    const formFields = [
      { id: 'moduleNumber', label: translate("global.module"), required: false, colSize: 'col-lg-3 col-md-6', valueDefault: selectDoor?.moduleNumber, disabled: true },
      { id: 'doorNumber', label: ('# '+ translate("global.door")), required: true, colSize: 'col-lg-3 col-md-6', valueDefault: selectDoor?.doorNumber },
    ];
  
    const selectFields = [
      { id: 'columnNumber', label: translate("global.column") , required: false, options: [{id: 1, value:"1"},{id: 2, value: "2"}], colSize: 'col-lg-3 col-md-6', valueDefault: selectDoor?.columnModule, disabled: openModeEdit ? true : false},
      { id: 'dimension', label: translate("dashboard.platforms.dimension") , required: true, options: [{id: "S", value: "S"}, {id: "M", value: "M"}, {id: "L", value: "L"}, {id: "XL", value: "XL"} ], colSize: 'col-lg-6 col-md-6', valueDefault: selectDoor?.dimension, disabled: false},
      { id: 'layer', label: translate("global.layer") , required: true, options: [{id: "H", value: "H - Height"}, {id: "M", value: "M - Medium"}, {id: "L", value: "L - Low"}], colSize: 'col-lg-6 col-md-6', valueDefault: selectDoor?.layer, disabled: false,  info: translate("global.representHeightFloor")},
      { id: 'isScreen', label: translate("global.controlDoor") , required: true, options: [ {id: 0, value: "No"}, {id: 1, value: "Yes"}], colSize: 'col-lg-6 col-md-6', valueDefault: getConditionValue(selectDoor?.isScreen), disabled: false, icon: MdLaptopChromebook, info: translate("global.representControlDoor")},
      { id: 'isActive', label: translate("global.lock"), required: true, options: [{id: 1, value: "No"}, {id: 0, value: "Yes"}], colSize: 'col-lg-6 col-md-6', valueDefault: getConditionValue(selectDoor?.isActive) , disabled: selectDoor?.isScreen===true ? true : false, icon: FaLock},
    ];
    
    const disabledInputsSelect = selectDoor?.boxitDoorId > 0 ? true : false;
    
    const handleIsDisabled = (valueInput) =>{

      if (valueInput) {
        return true
      } else {

        if (selectDoor?.boxitDoorId > 0) {

          if (openModeEdit) {
            return false;
          }
  
          return true;
          
        }
      }

    }

    const renderInputField = (props) => {
      const { id, label, required, colSize = "col-12", valueDefault = "", disabled, isHidden, Icon } = props;
      const isDisabled = handleIsDisabled(disabled);

      return (
        <div className={colSize}>
          {!isHidden && (
               <div className="d-flex justify-content-between align-items-center">
            
                 <label htmlFor={id} className="text-label-title">
                 {
                       Icon && <span className="mr-1"><Icon size={12} /></span>
                     }
      {label} {required && <span className="text-danger">*</span>}
                 </label>
     
                 
             </div>
          )}
          
          <div className="form-group mb-2">
            
              <input
              id={id}
              name={id}
              defaultValue={valueDefault}
              disabled={isDisabled} 
              type={id === "doorNumber" ? "text" : isHidden ? "hidden" : "text"} 
              ref={register({
                required: required && {
                  value: true,
                  message: translate('global.isRequerid'),
                },
              })}
              onChange={(e) => handleFieldChange(id, e.target.value)}
              className={`form-control form-control-md${errors[id] ? ' mb-2 is-invalid' : ''}`}
            />
            
            {errors[id] && <div className="invalid-feedback">{errors[id].message}</div>}
          </div> 
        </div>
      );
    };
    
    const renderSelectField = (props) => {
      const { id, label, required, options, colSize = "col-12", valueDefault = "", isHidden, disabled, icon: Icon, info } = props;
      const isDisabled = handleIsDisabled(disabled);
      
      return (
        <div className={colSize}>
          <div className="form-group mb-2">
            {!isHidden && (
              <>
                <div>
                  <label className="text-label-title" htmlFor={id}>
                    {Icon && <Icon />} {label} 
                    {required && <span className="text-danger">*</span>}
                    {info && (
                      <FaInfoCircle
                        title={info}
                        className="ml-1"
                        style={{ cursor: "pointer", color: "#424996" }}
                        back="red"
                      />
                    )}
                  </label>
                </div>
                <div className="input-group mb-3">
                  <select
                    id={id}
                    name={id}
                    defaultValue={valueDefault}
                    disabled={isDisabled}
                    onChange={(e) => handleFieldChange(id, e.target.value)}
                    ref={register({
                      required: required && {
                        value: true,
                        message: translate("global.isRequerid"),
                      },
                    })}
                    className={`form-control form-control-md${errors[id] ? " mb-2 is-invalid" : ""}`}
                  >
                    <option value="">Seleccione...</option>
                    {options.map((option, index) => (
                      <option key={index} value={option.id ?? index}>
                        {option?.value}
                      </option>
                    ))}
                  </select>
                </div>
                {errors[id] && <div className="invalid-feedback">{errors[id].message}</div>}
              </>
            )}
          </div>
        </div>
      );
    };    
    
    const handleFieldChange = (field, value) => { 
      const { moduleId, doorSequence } = selectDoor;
    
      setConfigLocker((prevConfig) => {
        const updatedConfig = JSON.parse(JSON.stringify(prevConfig));
    
        // Encuentra el módulo correspondiente
        const module = updatedConfig.modules.find((mod) => mod.moduleId === moduleId);
        if (!module) return prevConfig;
    
        // Encuentra la columna actual que contiene la puerta
        const currentColumn = module.columns.find((col) =>
          col.doors.some((door) => door.doorSequence === doorSequence)
        );
        if (!currentColumn) return prevConfig;
    
        const doorIndex = currentColumn.doors.findIndex(
          (door) => door.doorSequence === doorSequence
        );
        if (doorIndex === -1) return prevConfig;
    
        const door = currentColumn.doors[doorIndex];
    
        let updatedSelectDoor = { ...selectDoor }; // Hacemos una copia de selectDoor para actualizarlo correctamente
       
        if (field === "columnNumber" || field === "isScreen" || field === "isActive") {
           
        if (field === "columnNumber") {
          const newColumnNumber = parseInt(value, 10);
          if (currentColumn.columnId !== newColumnNumber) {
            const newColumn = module.columns.find(
              (col) => col.columnId === newColumnNumber
            );
    
            if (newColumn) {
              // Eliminar la puerta de la columna actual
              currentColumn.doors.splice(doorIndex, 1);
    
              // Buscar la primera secuencia disponible en la nueva columna
              if (door.boxitDoorId > 0) {
                // Si la puerta tiene boxitDoorId > 0, se le asigna una secuencia libre
                door.doorSequence = findAvailableSequenceInColumn(newColumn, module.endDoorNumber);
              }
    
              // Agregar la puerta a la nueva columna
              newColumn.doors.push({ ...door, columnModule: newColumnNumber });
    
              const startDoor = module.startDoorNumber;
    
              // Recalcular doorNumber y doorSequence solo para puertas con boxitDoorId === 0 
              let sequence = 0;
              let doorNumber = startDoor - 1;
    
              module.columns.forEach((col) => {
                col.doors.forEach((door, index) => {
                  // Solo actualizar doorNumber y doorSequence si boxitDoorId === 0
                  sequence++;
                  doorNumber++;
                  if (door.boxitDoorId === 0) {
                    door.doorNumber = doorNumber;
                    door.doorSequence = sequence;
                  }
                  if (selectDoor.id === door.id) {
                    updatedSelectDoor = {
                      ...updatedSelectDoor,
                      columnModule: newColumnNumber,
                      doorNumber: door.doorNumber,
                      doorSequence: door.doorSequence,
                    };
    
                    setSelectDoor(updatedSelectDoor);
                  }
                });
              });
            }
          }
        } 

          if (field === "isScreen" ) {
            const newValue = getConditionValue(value);
            door[field] = newValue;
            
            // Si la puerta es una pantalla, desactivar la puerta osea bloquearla
            if (field === "isScreen" && value === "1") {
              door.isActive = false;
              setSelectDoor((prev) => ({
                ...prev,
               isScreen: true, isActive: false })
              );
            }else {
              door.isActive = true;
              setSelectDoor((prev) => ({
                ...prev,
                isScreen: false, isActive: true
              }));
            }

          } 
          
          if(field === "isActive" ){
            const newValue = getConditionValue(value);
            door[field] = newValue;
            door.isActive = newValue;
            setSelectDoor((prev) => ({
              ...prev,
              isActive: newValue })
            );
          }
        }else {
            door[field] = value;
             // actualizar el setSelectDoor con los valores de la puerta
 setSelectDoor((prev) => ({
  ...prev,
  [field]: value
  }));
          }
        
    
        // Actualizar selectDoor aquí después de modificar la configuración
        return updatedConfig;
      });

    };
    // Función para encontrar la secuencia disponible en la nueva columna
    const findAvailableSequenceInColumn = (column, endDoor) => {
      // Buscar la primera secuencia disponible para las puertas con boxitDoorId > 0
      let sequence = 1;
      
      // Comprobamos que la secuencia no esté tomada y que no se pase del límite 'endDoor'
      const isSequenceTaken = (sequence) => column.doors.some((door) => door.doorSequence === sequence && door.boxitDoorId > 0);
    
      while (isSequenceTaken(sequence) && sequence <= endDoor) {
        sequence++;  // Incrementamos la secuencia hasta encontrar una libre
      }
    
      // Si la secuencia excede el límite de 'endDoor', limitamos la secuencia al valor de 'endDoor'
      if (sequence > endDoor) {
        return endDoor;
      }
    
      return sequence;
    };

    const handleSaveDoor = async (data) => {
      setIsLoading(true);
      setIsLoadingDoor(true);
      const abortController = new AbortController();
      

      const dataToSend = {
        ...selectDoor,
        doorId: selectDoor.boxitDoorId,
        isActive: getConditionValue(data.isActive),
        locationId: selectDoor.locationId,
        moduleId: selectDoor.moduleId,
        doorNumber: data.doorNumber,
        doorSequence: Number(selectDoor.doorSequence),
        dimension: data.dimension,
        layer: data.layer,
        isScreen: getConditionValue(data.isScreen),
        columnNumber: Number(data.columnNumber),
        isUpdate:  false,
        isAvailable: true,
      }      

      const response = await createDoorsPlatforms(dataToSend, abortController.signal); 

      if (response.code === 200) {
        setSelectDoor(null);
        setDoorsInBd((prevDoors) => {
          const updatedDoors = [...prevDoors, {...dataToSend, boxitDoorId: response.boxitDoorId, doorId: response.boxitDoorId}];
          return updatedDoors;
        });

        // Actualizar el boxitDoorId de la puerta en configLocker
        setConfigLocker((prevConfig) => {
          const updatedConfig = JSON.parse(JSON.stringify(prevConfig));
          const module = updatedConfig.modules.find((mod) => mod.moduleId === dataToSend.moduleId);
          if (!module) return prevConfig;
  
          const column = module.columns.find((col) => col.columnId === dataToSend.columnNumber);
          if (!column) return prevConfig;
          const door = column.doors.find((door) => door.doorSequence === dataToSend.doorSequence);
          if (!door) return prevConfig;
          door.boxitDoorId = response.boxitDoorId;
          return updatedConfig;
        });

        const { moduleId, doorSequence } = selectDoor;
        setConfigLocker((prevConfig) => {
          const updatedConfig = JSON.parse(JSON.stringify(prevConfig));
          const module = updatedConfig.modules.find((mod) => mod.moduleId === moduleId);
  
          if (!module) return prevConfig;
  
          // Encuentra la columna actual que contiene la puerta
          const currentColumn = module.columns.find((col) =>
            col.doors.some((door) => door.doorSequence === doorSequence)
          );
  
          if (!currentColumn) return prevConfig;
          
          const doorIndex = currentColumn.doors.findIndex(
            (door) => door.doorSequence === doorSequence
          );
  
          if (doorIndex === -1) return prevConfig;
  
          // Intenta seleccionar la siguiente puerta en la misma columna
          let nextDoor = currentColumn.doors[doorIndex + 1];
  
          // Si no hay más puertas en la columna, pasa a la primera puerta de la siguiente columna
          if (!nextDoor) {
            const currentColumnIndex = module.columns.indexOf(currentColumn);
            const nextColumn = module.columns[currentColumnIndex + 1];
  
            if (nextColumn && nextColumn.doors.length > 0) {
              nextDoor = nextColumn.doors[0];
            }
          }
  
          if (nextDoor) {
            // Actualizamos la puerta seleccionada
            setSelectDoor(nextDoor);
          }
  
          return updatedConfig;
        });

        setIsLoadingDoor(false);
        setOpenModalCompleteModule(true);
      }else{
         setModal({
                title: translate( "global.alert.attention"),
                body: translate("dashboard.packages.details.dropOff.receivePackage.NOT_FOUND"),
                footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
              });
              setShowModal(true);
              setIsLoadingDoor(false);
      }
      setIsLoading(false);
    }
 // Función para calcular el porcentaje de ocupación de un módulo
 const calculateModuleProgress = (module) => {
  const doorsInModule = doorsInBd.filter(
    (door) => door.moduleId === module.moduleId && door.locationId === module.locationId
  );
  const doorsInDatabase = doorsInModule.length;
  const totalDoorsInModule = module.numDoors;
  const doorsInDatabasePercentage = (doorsInDatabase / totalDoorsInModule) * 100;
  return Math.min(doorsInDatabasePercentage, 100).toFixed(0);
};

    const verifyCompleteModules = () => {
              const allModulesComplete = configLocker.modules.every(
                (module) => calculateModuleProgress(module) === "100"
              );              
              
              if (allModulesComplete) {
              
                setModal({
                  title: translate( "global.alert.ready"),
                  body: translate("global.allModulesComplete"),
                  footer: <div style={{ display: "flex", gap: "10px" }}>
                      <button className="btn btn-outline-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>
                      <button className="btn btn-fucsia" onClick={() =>  window.location.href = `/dashboard/platforms/general`}><FaHome className="mr-1"/>{translate("global.goToHome")}</button>
                  </div>,
                });
                setShowModal(true);
              }

              setOpenModalCompleteModule(false);
    }
    
    const toggleEditMode = () => {
      setEditModeDoors((prevMode) => !prevMode); // Alterna entre true y false
      setOpenModeEdit((prevMode) => !prevMode);
    };    

    const handleUpdateDoor = async () => {
      setIsLoading(true);
      setIsLoadingDoor(true);
      const abortController = new AbortController();
      const dataToSend = {
        ...selectDoor,
        doorId: selectDoor.boxitDoorId,
        locationId: selectDoor.locationId,
        moduleId: selectDoor.moduleId,
        doorNumber: String(selectDoor.doorNumber),
        doorSequence: Number(selectDoor.doorSequence),
        dimension: selectDoor.dimension,
        layer: selectDoor.layer,
        isLocked: selectDoor.isLocked,
        isScreen: isBoolean(selectDoor.isScreen) ? selectDoor.isScreen : getConditionValue(selectDoor.isScreen),
        columnNumber: Number(selectDoor.columnModule),
        isActive: isBoolean(selectDoor.isActive) ? selectDoor.isActive : getConditionValue(selectDoor.isActive),
        isAvailable: true,
        isUpdate: true,
      }      

      const response = await updateDoorPlatform(dataToSend, abortController.signal);

      setIsLoading(false);
      if (response.code === 200) {
        setModal({
          title: translate( "global.alert.ready"),
          body: translate("global.updateSuccessDoor"),
          footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.ok')}</button>,
        });
        setShowModal(true);
        setOpenModeEdit(false);
      }else{
        setModal({
          title: translate( "global.alert.attention"),
          body: translate("dashboard.packages.details.dropOff.receivePackage.NOT_FOUND"),
          footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
        });
        setShowModal(true);
      }

      setIsLoadingDoor(false);
    };
      
    const handleCancel = () => {
      // Restablece a los valores originales
      if (originalConfigLocker && originalSelectDoor) {
        setConfigLocker(JSON.parse(JSON.stringify(originalConfigLocker)));
        setSelectDoor(JSON.parse(JSON.stringify(originalSelectDoor)));
      }
      setOpenModeEdit(false); // Salir de modo edición
    };
    
    useEffect(() => {
      if (selectDoor) {
        
        // Actualiza los valores del formulario cuando selectDoor cambia
        setValue("moduleNumber", selectDoor.moduleNumber || "");
        setValue("doorNumber", selectDoor.doorNumber || "");
        setValue("columnNumber", selectDoor.columnModule || "");
        setValue("dimension", selectDoor.dimension || "");
        setValue("layer", selectDoor.layer || "");
        setValue("isScreen", getConditionValue(selectDoor.isScreen || false));
      setValue("isActive", getConditionValue(selectDoor.isActive || false));
      setValue("doorSequence", selectDoor.doorSequence || "");
      }
    }, [selectDoor, setValue]);

    useEffect(() => {
      if (openModeEdit) {
        // Guarda los estados originales al entrar en modo edición
        setOriginalConfigLocker(JSON.parse(JSON.stringify(configLocker)));
        setOriginalSelectDoor(JSON.parse(JSON.stringify(selectDoor)));
      }
    }, [openModeEdit]);

    useEffect(() => {
    if(openModalCompleteModule){
      verifyCompleteModules();  
    }
    }, [openModalCompleteModule]);
    

    
  return (
    <form className="cardInternal" onSubmit={handleSubmit(handleSaveDoor)}>
      <div className="cardInternal-header">
        <div className="cardInternal-title">
          <span className="mr-1">
            <FaDoorOpen size={18} />
          </span>
          {translate("global.doors", "capital")}
        </div>
        <div>
          {selectDoor && (
            <div style={{display:"flex",alignItems:"center", gap:'15px'}}>

            {selectDoor?.boxitDoorId > 0 && <BtnSync mode="door" doorId={selectDoor?.boxitDoorId}/> }

<div>
  
            {
              (!openModeEdit) ? (
  selectDoor?.boxitDoorId > 0 && (
    <>
<button className="btn btn-gray btn-sm mr-2" type="button" onClick={() => toggleEditMode()}>
             <FaEdit/> {translate("global.edit")}
         </button>

          <button className="btn btn-gray btn-sm mr-2" type="button" onClick={() => setSelectDoor(null)}>
              {translate("global.close")}
         </button>
    </>
  )
              ): (
                <>
<button className="btn btn-gray btn-sm mr-2" type="button" onClick={handleCancel}>
               {translate("global.cancel") }
              </button>
                <button className="btn btn-blue btn-sm mr-2" type="button" onClick={handleUpdateDoor}>
                {isLoading ? (<Spinner type={20} />) : <span className='mr-1'><FaSave /></span>} {translate("global.save")}
              </button>
                </>
              )
            }
          
            
            {!disabledInputsSelect && (
              <>
                {selectDoor?.boxitDoorId <= 0 && (
                  <div className="d-flex justify-content-end" style={{ gap: "5px" }}>
                    <button className="btn btn-gray btn-sm mr-2" type="button" onClick={() => setSelectDoor(null)}>
               {translate("global.close") }
              </button>
                  <button className="btn btn-blue btn-sm" type="submit" disabled={isLoading}>
                      {isLoading ? (<Spinner type={20} />) : <span className='mr-1'><FaSave /></span>} {translate("global.save")}
                   </button>
                  </div>
                      
                )}
              </>
            )}
            
</div>
          
        </div>
          )}
        
        </div>
      </div>
      <div className="cardInternal-body">
        {selectDoor ? (
          <div className="row">
            {/* Render Input Fields */}
            {formFields.map((field, index) => renderInputField({ ...field, key: index }))}

            {/* Render Select Fields */}
            {selectFields.map((field, index) => renderSelectField({ ...field, key: index }))}


            {/* {renderInputField({ id: 'doorSequence', label: 'doorSequence', required: false, colSize: 'col-12', valueDefault: selectDoor?.doorSequence, disabled: true,  })} */}
          </div>
        ) : (
          <div className="messageAlert">
            {translate("global.messageSelectDoorInfo")}
          </div>
        )}
      </div>
      <ModalContent
                        title={modal.title}
                        body={modal.body}
                        footer={modal.footer}
                        isOpenModal={showModal}
                        onHide={() => setShowModal(false)}
                />
    </form>
  );
};

export default FormDoorsLocker;
