import React, { useEffect, useState } from 'react';
import { FaArrowCircleRight, FaEdit, FaFileAlt, FaSave } from 'react-icons/fa';
import './index.css';
import { translate } from '../../../../utils/Common';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { usePlatforms } from '../../../../hooks/usePlatforms';
import Skeleton from '../../../../components/ui/Skeleton';
import Spinner from '../../../../components/Spinner';
import ModalContent from '../../../../components/ModalContent';
import BtnSync from '../../../../components/BtnSync';


const FormGeneralLocker = (props) => {
  const { dataDetails, setDataDetails,setOptionSelect, setExternalId, isLoadingData, typeView, optionsProviders } = props;

  const {createPlatform, getPlatformDetailsByExternalId, updateGeneralDataPlatform } = usePlatforms();
  const { register, errors, handleSubmit, setError, clearErrors, reset  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({ title: '', body: '', footer: '' });
  const [showModal, setShowModal] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const [editMode, setEditMode] = useState(true);

  const formFields = [
    { id: 'locationName', label: translate('dashboard.platforms.name'), required: true, colSize: 'col-12', valueDefault: dataDetails?.namePlatform, disabled: editMode },
    { id: 'locationId', label: translate("dashboard.platforms.support.openLockerCard.locationId"), required: true, colSize: 'col-6', valueDefault: dataDetails?.externalId, disabled: (typeView==='edit' && true) },
    { id: 'city', label: translate("global.City"), required: false, colSize: 'col-6', valueDefault: dataDetails?.city, disabled: editMode },
    { id: 'address', label: translate("global.physicalAddress"), required: false, colSize: 'col-12', valueDefault: dataDetails?.fisicAddress, disabled: editMode },
  ];

  const selectFields = [
    { id: 'providerId', label: translate("global.provider"), required: true, options: optionsProviders, colSize: 'col-8', valueDefault: Number(dataDetails?.IdFabricante), disabled: editMode ? true : (typeView==='create' ? false : true) },
    { id: 'generation', label: translate("global.generation"), required: true, options: [{id: 1, value:'1'}, {id: 2, value:'2'}, {id: 3, value:'3'}], colSize: 'col-4', valueDefault: dataDetails?.IdGeneration, disabled: editMode },
    { id: 'typeLocker', label: translate("global.typeLocker"), required: false, options: [{id: 1, value:'Indoor'}, {id: 2, value:'Outdoor'}], colSize: 'col-8', valueDefault: dataDetails?.IdTypeLocker, isHidden: (dataDetails && !dataDetails?.IdTypeLocker), disabled: editMode },
  ];

  const renderInputField = (props) => {
    const { id, label, required, colSize = "col-12", valueDefault = "", disabled } = props;

    return (
      <div className={colSize}>
        {isLoadingData ? <div className='mt-1 mb-2'><Skeleton width={100} height={10} /></div> : <label className="text-label-title" htmlFor={id}>
            {label} {required && <span className='text-danger'>*</span>}
          </label>}
        <div className="form-group mb-2">
          {isLoadingData ? <div className='mt-2 mb-3'><Skeleton width='100%' height={40} /></div> : (
            <input
            id={id}
            name={id}
            defaultValue={valueDefault}
            disabled={disabled} 
            ref={register({
              required: required && {
                value: true,
                message: translate('global.isRequerid'),
              },
            })}
            className={`form-control form-control-md${errors[id] ? ' mb-2 is-invalid' : ''}`}
          />
          )}
          
          {errors[id] && <div className="invalid-feedback">{errors[id].message}</div>}
        </div> 
      </div>
    );
  };
  
  const renderSelectField = (props) => {
    const { id, label, required, options, colSize = "col-12", valueDefault = "", isHidden, disabled} = props;    
    
    return (
      <div className={colSize}>
      <div className="form-group mb-2">
        {
          !isHidden && (
            <>
{isLoadingData ? (
          <div className="mt-1 mb-2">
            <Skeleton width={100} height={10} />
          </div>
        ) : (
          <label className="text-label-title" htmlFor={id}>
            {label} {required && <span className="text-danger">*</span>}
          </label>
        )}
        <div className="mb-3">
          {isLoadingData ? (
            <div className="mt-2 mb-3">
              <Skeleton width="100%" height={40} />
            </div>
          ) : (
            <select
              id={id}
              name={id}
              defaultValue={valueDefault}
              disabled={disabled} 
              ref={register({
                required: required && {
                  value: true,
                  message: translate('global.isRequerid'),
                },
              })}
              className={`form-control form-control-md${errors[id] ? ' mb-2 is-invalid' : ''}`}
            >
              <option value="">Seleccione...</option>
              {options.map((option, index) => (
                <option key={index} value={option.id}>
                  {option?.value}
                </option>
              ))}
            </select>
          )}
        </div>
        {errors[id] && <div className="invalid-feedback">{errors[id].message}</div>}
            </>
          )
        }
        
      </div>
    </div>);
  };

  const verifyExistExternalId = async (externalId) => {
    const response = await getPlatformDetailsByExternalId(externalId);
  
    if (response) {
      setError('locationId', { type: 'manual', message: translate("global.externalIdInUse") });
      return true;
    } else {
      clearErrors('locationId');
      return false;
    }
  }

  const submitCreatePlatform = async (data) => { 
    setIsLoading(true);

    const dataToSend = {
      ...data,
     typeLocker: Number(data.typeLocker),
    }

    const abortController = new AbortController();
    const validateSubmit = await verifyExistExternalId(dataToSend.locationId); 
    if (validateSubmit) {
      setIsLoading(false);
      return;
    };
  
    const response = await createPlatform(dataToSend, abortController.signal);
    
    if (response.code === 200) {
        if (response.boxitLocationId > 0) {
           // Agregar el parámetro extId en la URL
           const url = new URL(window.location.href);
           url.searchParams.set('extId', data.locationId);
           url.searchParams.set('view', 'edit');
           window.history.pushState({}, '', url); 
           setExternalId(data.locationId);
           setOptionSelect('settings');
        }
    } else {
      setModal({
        title: translate( "global.alert.attention"),
        body: translate("dashboard.packages.details.dropOff.receivePackage.NOT_FOUND"),
        footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
      });
      setShowModal(true);
    }
    setIsLoading(false);
  }

  const submitUpdatePlatform = async (data) => { 
    setIsLoading(true);
    const abortController = new AbortController();
    
  const dataToSend = {
    ...data,
    statusId: dataDetails.IdEstatus,
    environmentId: dataDetails.IdTipoAmbiente,
    locationId: dataDetails.externalId,
    typeLocker: Number(data.typeLocker),
    providerId: Number(dataDetails.IdFabricante),
  }
  
    const response = await updateGeneralDataPlatform(dataToSend, abortController.signal);
   
    if (response.code === 200) {
      setModal({
        title: translate( "global.alert.ready"),
        body: translate("global.updateSuccessGeneralDataPlatform"),
        footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
      });
      setShowModal(true);
      setEditMode(true);
      setOriginalData(data); 

      // Actualiza los DATOS CON LOS DATOS ANTERIORES
      setDataDetails((prevData) => ({
        ...prevData,
        fisicAddress: data.address,
        namePlatform: data.locationName,
        IdGeneration: Number(data.generation),
        IdFabricante: Number(dataDetails.IdFabricante),
        IdTypeLocker: Number(data.typeLocker)
      }));

    } else {
      setModal({
        title: translate( "global.alert.attention"),
        body: translate("dashboard.packages.details.dropOff.receivePackage.NOT_FOUND"),
        footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
      });
      setShowModal(true);
      reset(originalData); // Restablece los valores originales en el formulario
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (typeView === 'edit' && dataDetails) {
      setEditMode(true);
      setOriginalData(dataDetails); // Guarda los datos originales
      reset(dataDetails); // Establece los valores iniciales en el formulario
      return;
    }
    if (typeView === 'create') {
      setEditMode(false);
      return;
    }

  }, [typeView]);

  return (
    <form className='cardInternal' onSubmit={handleSubmit(typeView === 'create' ? submitCreatePlatform : submitUpdatePlatform)}>
      <div className="cardInternal-header">
        <div className='cardInternal-title'>
          <span className="mr-1"><FaFileAlt /></span>
          {translate("global.generalData")}
        </div>
        <div className="cardInternal-actions" style={{display:"flex",alignItems:"center", gap:'15px'}}>
          {dataDetails?.IdPlataforma && <BtnSync mode="general" platformId={dataDetails?.IdPlataforma}/> }
          {
            !isLoadingData && (
            dataDetails ? (
              <div className="d-flex align-items-center " style={{gap:'10px'}}>
              {
                editMode ? (
              <button className="btn btn-gray btn-sm" type='button' onClick={() => setEditMode((prevMode) => !prevMode)}>
                <FaEdit/> {translate("global.edit")} 
              </button>
                ):(
                  <>
                    <button className="btn btn-light btn-sm" type='button' onClick={() => {
                      reset(originalData);
                      setEditMode((prevMode) => !prevMode);
                      }} >
                     {translate("global.cancel")} 
                  </button>
                  <button className="btn btn-blue btn-sm" type='submit' disabled={isLoading}>
                   {!isLoading ? <FaSave /> : <Spinner type={1} />} {translate("global.save")} 
                  </button>
                  </>
                )
              }
              
              {editMode && <button className="btn btn-gray btn-sm" onClick={() => setOptionSelect('settings')}>
               {translate("login.signup.btn.next")} <FaArrowCircleRight />
              </button>
              }
              </div>
              
            ) : (
            <button className="btn btn-blue btn-sm" disabled={isLoading} type="submit">
              <span className='mr-1'>{isLoading ? (<Spinner type={20} />) : (<FaSave />)}</span> {translate("global.save")}
            </button>
            ) )
          }
        </div>
      </div>
      <div className="cardInternal-body">
        <div className="row">
          {formFields.map((field) => renderInputField(field))}
          {selectFields.map((field) => renderSelectField(field))}
        </div>
      </div>
      <ModalContent
                        title={modal.title}
                        body={modal.body}
                        footer={modal.footer}
                        isOpenModal={showModal}
                        onHide={() => setShowModal(false)}
                />
    </form>
  );
};

export default FormGeneralLocker;