import React, { useEffect, useState } from "react";
import "../index.css";
import { MdLaptopChromebook, MdOutlineViewInAr } from "react-icons/md";
import { getConditionValue, translate } from "../../../../../utils/Common";
import { FaHome, FaLock } from "react-icons/fa";
import NumberWithPercentage from "../../../../../components/NumberWithPercentage";
import { FaComputer } from "react-icons/fa6";
import ModalContent from "../../../../../components/ModalContent";

const PreviewLocker = (props) => {
  const { data, config, setSelectDoor, selectDoor, doorsInBd, setOptionSelect, isLoadingDoor, setModulesIsCompleted, editModeModule, editModeDoors } = props;  

  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({ title: '', body: '', footer: '' });

  const getDoorSize = (layer) => {
    switch (layer) {
      case "S":
        return { minHeight: "40px", minWidth: "60px" };
      case "M":
        return { minHeight: "60px", minWidth: "80px" };
      case "L":
        return { minHeight: "80px", minWidth: "100px" };
      default:
        return { minHeight: "50px", minWidth: "70px" };
    }
  };

  const getClassDoorColor = (module, door) => {
const isLoading = isLoadingDoor;


    if ((selectDoor?.id || selectDoor?.boxitDoorId) === (door?.id || door?.boxitDoorId)) {
      return "module-door-selected " + (isLoading ? " module-door-loading" : "");
    }
    
    if (door?.boxitDoorId > 0) {
      return "module-door-check " + (isLoading ? " module-door-loading" : "");  // Si la puerta está en la base de datos, asigna la clase "module-door-check"
    }
  
  
  
    if (module.status === 1) {
      return "module-door-draft " + (isLoading ? " module-door-loading" : "");
    }
  
    return "module-door-render " + (isLoading ? " module-door-loading" : "");
  };
  

  const handleSelectDoor = (module, door) => {    
    
    if(module.moduleId){
      const url = new URL(window.location.href);
      url.searchParams.set('opt', 'doors');
      window.history.pushState({}, '', url);
    
      // Usar un identificador único (puede ser door.doorSequence) para la puerta
      setSelectDoor((prevDoor) => {
        // Si la puerta seleccionada ya es la misma, la deseleccionamos
        return prevDoor?.doorSequence === door.doorSequence ? null : door;
      });
  
      setOptionSelect('doors');
    }
  };

    // Función para calcular el porcentaje de ocupación de un módulo
    const calculateModuleProgress = (module) => {
      const doorsInModule = doorsInBd.filter(
        (door) => door.moduleId === module.moduleId && door.locationId === module.locationId
      );
      const doorsInDatabase = doorsInModule.length;
      const totalDoorsInModule = module.numDoors;
      const doorsInDatabasePercentage = (doorsInDatabase / totalDoorsInModule) * 100;
      return Math.min(doorsInDatabasePercentage, 100).toFixed(0);
    };

    // useEffect(() => {
    //   if (config?.modules?.length > 0) {
    //     const allModulesComplete = config.modules.every(
    //       (module) => calculateModuleProgress(module) === "100"
    //     );
        
    //     if (allModulesComplete && !editModeModule && !editModeDoors) {
    //       setModal({
    //         title: translate( "global.alert.ready"),
    //         body: translate("global.allModulesComplete"),
    //         footer: <div style={{ display: "flex", gap: "10px" }}>
    //             <button className="btn btn-outline-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>
    //             <button className="btn btn-fucsia" onClick={() =>  window.location.href = `/dashboard/platforms/general`}><FaHome className="mr-1"/>{translate("global.goToHome")}</button>
    //         </div>,
    //       });
          
    //       setShowModal(true);
    //       setModulesIsCompleted && setModulesIsCompleted(true);
    //     }
    //   }
    // }, [config]);
    

  return (
    <div className="cardInternal">
      <div className="cardInternal-header">
        <div className="cardInternal-title">
          <span className="mr-1">
            <MdOutlineViewInAr size={20} />
          </span>
          {translate("global.preview")}
        </div>
      </div>
      <div className="cardInternal-body">
        {/* DATA DETAILS PLATFORM */}
        {data?.IdPlataforma && (
          <div>
            <span className="title-preview">{data?.namePlatform}</span> -{" "}
            <span className="title-num-preview">
              {data?.IdPlataforma ? "ID. " + data?.externalId : " "}
            </span>
          </div>
        )}
        {config?.modules?.length > 0 ? (
          <div className="modules-container">
            {config.modules.map((module, moduleIndex) => {
              const moduleProgress = calculateModuleProgress(module); 
              return(
              <div key={moduleIndex} className="module-wrapper">
                <div className="d-flex mb-2 " style={{ alignItems: "center", gap: "10px" }}>
                {
                  moduleProgress > 0 && (
                    <div className="module-progress">
                    <NumberWithPercentage number={moduleProgress} /> 
                  </div>
                  )
                }
                 
                
                <div className="module-title">
                    {translate("global.module")}{" "}
                    <span className="text-bold">{module.moduleNumber}</span>
                  </div>

                  {
                  module.isUnitCentral && (
                    <div className="circle-gray-icon">
                      <FaComputer  size={18} color='#47fa7f'/>
                    </div>
                  )
                }
                </div>

               
                 
                <div
                  className={
                    module.status === 1
                      ? "module-container-draft"
                      : "module-container"
                  }
                >
                  <div className="columns-container">
                    {module.columns.map((column, columnIndex) => (
                      <div key={columnIndex} className="column">
                        {column.doors.map((door, doorIndex) => {
                          const doorStyle = getDoorSize(door?.dimension);
                          return (
                            <div
                              key={door.doorSequence}
                              className={getClassDoorColor(module, door)}
                              onClick={() => !isLoadingDoor && handleSelectDoor(module, door)}
                              style={{ cursor: module.moduleId ? "pointer" : "not-allowed" }}
                            >
                              <div>
                                <span>{door.doorNumber}</span>
                                {!door?.isActive && (
                                  <span
                                    className="ml-2"
                                    style={{
                                      background: "gray",
                                      padding: "6px",
                                      borderRadius: "0px 0px 16px 16px",
                                    }}
                                  >
                                    <FaLock />
                                  </span>
                                )}
                                {door?.isScreen && (
                                  <span
                                    className="ml-2"
                                    style={{
                                      background: "#0058ff",
                                      padding: "6px",
                                      borderRadius: "0px 0px 16px 16px",
                                    }}
                                  >
                                    <MdLaptopChromebook size={18} />
                                  </span>
                                )}
                              </div>
                              <div>
                                {door?.isScreen
                                  ? "C - "
                                  : door?.dimension &&
                                    `${door.dimension} - `}{" "}
                                {door.layer}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )})}
          </div>
        ) : (
          <div className="messageAlert">
            {translate("global.messageNoPlatformModuleCreated")}
          </div>
        )}
      </div>
      {showModal && (
<ModalContent
title={modal.title}
body={modal.body}
footer={modal.footer}
isOpenModal={showModal}
onHide={() => setShowModal(false)}
/>
      )}
    </div>
  );
};

export default PreviewLocker;
