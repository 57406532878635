import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as GiIcons from "react-icons/gi";
import { translate, verifyHideCountry, verifyHideCountry2, verifyHideCountryItems} from "../utils/Common";
import { LuAlertCircle } from "react-icons/lu";

const optionsPages = {
  verifyCountry: verifyHideCountry(),
  iconsClosed: {
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  actions: {
    read: {
        1: "view_availability_doors",
        2: "view_movements_doors",
        3: "view_screen_platforms",
        4: "view_screen_billing_admin",
        5: "view_screen_support_platforms",
        6 : "view_package_options_details",
        7: "view_qrcode_package",
        8: "view_locate_platform",
        9: "view_screen_arching",
        10: "view_screen_clients",
        11: "view_screen_alerted_packages",
        12: "view_screen_generate_qr",
        13: "view_screen_create_platform"
    },
    create: {
      1: "create_package",
      2: "create_locker"
    },
    update: {
      1: "update_package",
      2: "open_door_remote",
      3: "update_locker",
      4: "lock_doors",
      5: "unlock_doors",
      6: "update_package_expiration",
      7: "reset_locker",
      8: "update_change_platform",
      9: "update_change_info_package",
      10: "update_reservation_package",
      11: "update_force_delivery_package",
      12: "update_change_client",
      13: "update_management_balance",
    },
    delete: {
      1: "delete_package",
      2: "cancel_package",
      3: "delete_locker",
      4: "remove_package",
    },
    search : {
        1: "search_package",
        2: "search_locker"
    }
  },
};

const pagesConfig = {
  1: {
    title: translate("sidebar.options.start"),
    path: "/dashboard/",
    icon: <FaIcons.FaHome />,
  },
  2: {
    title: translate("sidebar.options.packages.load"),
    path: "/dashboard/packages/load",
  },
  3: {
    title: translate("sidebar.options.packages.loadpackage"),
    path: "/dashboard/packages/loadpackage",
    actions : []
  },
  4: {
    title: translate("sidebar.options.packages.reservations"),
    path: "/dashboard/lockers/reservations",
    icon: <BsIcons.BsInboxesFill />,
  },
  5: {
    title: translate("sidebar.options.packages.deliveries"),
    path: "/dashboard/lockers/awaitingDelivery",
    icon: <FaIcons.FaCar />,
  },
  6: {
    title: translate("sidebar.options.packages.tracking"),
    path: "/dashboard/packages/tracking",
    actions: [],
  },
  7: {
    title: translate("sidebar.options.platforms.general"),
    path: "/dashboard/platforms/general",
    actions:[]
  },
  8: {
    title: translate("sidebar.options.platforms.support"),
    path: "/dashboard/platforms/support",
    actions: []
  },
  9: {
    title: translate("global.registerIncident"),
    path: "/dashboard/incidents/register",
  },
  10: {
    title: translate("global.listIncident"),
    path: "/dashboard/incidents",
  },
  11: {
    title: translate("sidebar.options.counter.dropoff"),
    path: "/dashboard/packages/dropoff",
  },
  12: {
    title: translate("sidebar.options.counter.pickup"),
    path: "/dashboard/packages/pickup",
  },
  13: {
    title: translate("sidebar.options.faqs"),
    path: "/dashboard/faqs",
    icon: <FaIcons.FaQuestion />,
  },
  14: {
    title: translate("global.InvoicingAndUsage"),
    path: "/dashboard/billing",
    icon: <BsIcons.BsCreditCard />,
  },
  15: {
    title: translate("sidebar.options.statistics"),
    path: "/dashboard/insights",
    icon: <FaIcons.FaChartPie />,
  },
  16: {
    title: translate("sidebar.options.accounting.searchTransactions"),
    path: "/dashboard/accounting",
    icon: <BsIcons.BsCashCoin />,
  },
  17: {
    title: translate("sidebar.options.accounting.billing"),
    path: "/dashboard/adminBilling",
    icon: <BsIcons.BsCashCoin />,
  },
  18: {
    title: translate("global.clients"),
    path: "/dashboard/clients",
    icon: <BsIcons.BsPeople />,
  },
  19: {
    title: translate("global.locate"),
    path: "/dashboard/platforms/locate",
    icon: <BsIcons.BsPeople />,
  },
  20: {
    title: translate("sidebar.options.accounting.arching"),
    path: "/dashboard/accounting/arching",
    actions:[]
  },
  21: {
    title: translate("sidebar.options.packages.alerted"),
    path: "/dashboard/packages/alerted",
    actions: [],
  },
  22: {
    title: translate("sidebar.options.platforms.GenerateQR"),
    path: "/dashboard/platforms/GenerateQR",
    actions: [],
  },
  23: {
    title: translate("sidebar.options.platforms.createPlatform"),
    path: "/dashboard/platforms/settings?view=create",
    actions: [],
  }
};

const modulesPages = {
  1: {
    title: translate("sidebar.options.packages"),
    path: "/dashboard/packages/",
    icon: <FaIcons.FaCube />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    ...optionsPages.iconsClosed,
    subNav: [],
  },
  2: {
    title: translate("sidebar.options.platforms"),
    path: "/dashboard/platforms/",
    icon: <GiIcons.GiPlatform />,
    ...optionsPages.iconsClosed,
    subNav: [],
  },
  3: {
    title: translate("sidebar.options.incidents"),
    path: "/dashboard/incidents",
    icon: <LuAlertCircle />,
    ...optionsPages.iconsClosed,
    subNav: [],
  },
  4: {
    title: translate("sidebar.options.counter"),
    path: "/dashboard/packages/",
    icon: <FaIcons.FaTabletAlt />,
    ...optionsPages.iconsClosed,
    isVisible: optionsPages.verifyCountry,
    subNav: [],
  },
  5: {
    title: translate("sidebar.options.accounting"),
    path: "/",
    icon: <BsIcons.BsCashCoin />,
    ...optionsPages.iconsClosed,
    isVisible: optionsPages.verifyCountry,
    subNav: [],
  },
};

export const configRolesUsers = {
  1: {
    idRole: 1,
    name: translate("global.typesRoleBli.1"),
    idTypeUser: 3,
    options: [
      pagesConfig[1],
      {
        ...modulesPages[1],
        subNav: [
          pagesConfig[2],
          {
            ...pagesConfig[3],
            actions: [
              optionsPages.actions.create[1],
              optionsPages.actions.search[2],
              optionsPages.actions.delete[2],
              optionsPages.actions.update[2]
            ]
          },
          {
            ...pagesConfig[6],
            actions: [
              optionsPages.actions.search[1],
              optionsPages.actions.read[6],
              optionsPages.actions.update[8],
              optionsPages.actions.update[9],
              optionsPages.actions.update[10],
              optionsPages.actions.delete[4],
              optionsPages.actions.read[7]
            ]
          }
        ]
      },
      pagesConfig[4],
      pagesConfig[5],
      {
        ...modulesPages[2],
        subNav: [
          {
            ...pagesConfig[19],
            actions: [optionsPages.actions.read[8]]
          }
        ]
      },
      pagesConfig[14],
      pagesConfig[13]
    ]
  },

  2: {
    idRole: 2,
    name: translate("global.typesRoleBli.2"),
    idTypeUser: 3,
    options: [
      pagesConfig[1],
      {
        ...modulesPages[1],
        subNav: [
          pagesConfig[2],
          {
            ...pagesConfig[3],
            actions: [
              optionsPages.actions.create[1],
              optionsPages.actions.search[2],
              optionsPages.actions.delete[2],
              optionsPages.actions.update[2]
            ]
          },
          {
            ...pagesConfig[6],
            actions: [
              optionsPages.actions.search[1],
              optionsPages.actions.read[6],
              optionsPages.actions.update[8],
              optionsPages.actions.update[9],
              optionsPages.actions.update[10],
              optionsPages.actions.delete[4],
              optionsPages.actions.read[7]
            ]
          }
        ]
      },
      pagesConfig[4],
      pagesConfig[5],
      {
        ...modulesPages[2],
        subNav: [
          {
            ...pagesConfig[19],
            actions: [optionsPages.actions.read[8]]
          }
        ]
      },
      pagesConfig[13]
    ]
  },

  3: {
    idRole: 3,
    name: translate("global.typesRoleBli.3"),
    idTypeUser: 3,
    options: [
      pagesConfig[1],
      {
        ...modulesPages[1],
        subNav: [
          {
            ...pagesConfig[2]
          },
          {
            ...pagesConfig[3],
            actions: [
              optionsPages.actions.create[1],
              optionsPages.actions.search[2],
              optionsPages.actions.delete[2],
              optionsPages.actions.update[2]
            ]
          },
          {
            ...pagesConfig[6],
            actions: [
              optionsPages.actions.update[8],
              optionsPages.actions.update[9],
              optionsPages.actions.update[10],
              optionsPages.actions.update[11],
              optionsPages.actions.delete[4],
              optionsPages.actions.read[7],
              optionsPages.actions.update[6]
            ]
          }
        ]
      },
      pagesConfig[4],
      pagesConfig[5],
      {
        ...modulesPages[2],
        subNav: [
          {
            ...pagesConfig[7],
            actions: [
              optionsPages.actions.read[1],
              optionsPages.actions.update[2],
              optionsPages.actions.read[2],
              optionsPages.actions.read[3],
              optionsPages.actions.update[7],
              optionsPages.actions.read[6]
            ]
          },
          {
            ...pagesConfig[8],
            actions: [optionsPages.actions.read[5]]
          },
          {
            ...pagesConfig[19],
            actions: [optionsPages.actions.read[8]]
          },
          ...(verifyHideCountry2() ? [{
              ...pagesConfig[22],
              actions: [optionsPages.actions.read[12]]
          }] : [])
        ]
      },
      {
        ...modulesPages[3],
        subNav: [pagesConfig[9], pagesConfig[10]]
      },
      pagesConfig[13]
    ]
  },

  4: {
    idRole: 4,
    name: translate("global.typesRoleBli.4"),
    idTypeUser: 3,
    options: [
      pagesConfig[1],
      {
        ...modulesPages[2],
        subNav: [
          {
            ...pagesConfig[7],
            actions: [
              optionsPages.actions.read[1],
              optionsPages.actions.update[2],
              optionsPages.actions.read[2],
              optionsPages.actions.update[4],
              optionsPages.actions.update[5],
              optionsPages.actions.update[3],
              optionsPages.actions.read[3],
              optionsPages.actions.update[7],
              optionsPages.actions.read[13]
            ]
          },
          {
            ...pagesConfig[8],
            actions: [optionsPages.actions.read[5]]
          },
          ...(verifyHideCountry2() ? [{
              ...pagesConfig[22],
              actions: [optionsPages.actions.read[12]]
          }] : [])
        ]
      },
      {
        ...pagesConfig[18],
        actions: [
          optionsPages.actions.read[10]
        ]
      },
    ]
  },

  5: {
    idRole: 5,
    name: translate("global.typesRoleBli.5"),
    idTypeUser: 3,
    isAdmin: true,
    options: [
      pagesConfig[1],
      {
        ...modulesPages[1],
        subNav: [
          pagesConfig[2],
          {
            ...pagesConfig[3],
            actions: [
              optionsPages.actions.create[1],
              optionsPages.actions.search[2],
              optionsPages.actions.delete[2],
              optionsPages.actions.update[2]
            ]
          },
          pagesConfig[4],
          pagesConfig[5],
          {
            ...pagesConfig[6],
            actions: [
              optionsPages.actions.search[1],
              optionsPages.actions.read[6],
              optionsPages.actions.update[8],
              optionsPages.actions.update[9],
              optionsPages.actions.update[10],
              optionsPages.actions.update[11],
              optionsPages.actions.delete[4],
              optionsPages.actions.read[7],
              optionsPages.actions.update[6]
            ]
          }
        ]
      },
      {
        ...modulesPages[5],
        subNav: [
          pagesConfig[16],
          {
            ...pagesConfig[17],
            actions: [optionsPages.actions.read[4]]
          },
          {
            ...pagesConfig[20],
            actions: [
              optionsPages.actions.read[9],
              optionsPages.actions.read[3]
            ]
          }
        ]
      },
      {
        ...modulesPages[3],
        subNav: [pagesConfig[9], pagesConfig[10]]
      },
      {
        ...modulesPages[2],
        subNav: [
          {
            ...pagesConfig[7],
            actions: [
              optionsPages.actions.read[1],
              optionsPages.actions.update[2],
              optionsPages.actions.read[2],
              optionsPages.actions.update[4],
              optionsPages.actions.update[5],
              optionsPages.actions.update[3],
              optionsPages.actions.read[3],
              optionsPages.actions.update[7]
            ]
          },
          {
            ...pagesConfig[19],
            actions: [optionsPages.actions.read[8]]
          },
          ...(verifyHideCountry2() ? [{
              ...pagesConfig[22],
              actions: [optionsPages.actions.read[12]]
          }] : [])
        ]
      },
      {
        ...pagesConfig[18],
        actions: [
          optionsPages.actions.update[13],
          optionsPages.actions.read[10]
        ]
      },
      pagesConfig[13]
    ]
  },

   6:     {
      idRole: 6,
      name: translate("global.typesRoleBli.6"),
      idTypeUser: 3,
      isAdmin: true,
      options: [
          pagesConfig[1],
          {
              ...modulesPages[1],
              subNav: [
                  pagesConfig[2],
                  {
                      ...pagesConfig[3],
                      actions: [
                          optionsPages.actions.create[1],
                          optionsPages.actions.search[2],
                          optionsPages.actions.delete[2],
                          optionsPages.actions.update[2]
                      ]
                  },
                  pagesConfig[4],
                  pagesConfig[5],
                  {
                      ...pagesConfig[6],
                      actions: [
                          optionsPages.actions.search[1],
                          optionsPages.actions.read[6],
                          optionsPages.actions.update[8],
                          optionsPages.actions.update[9],
                          optionsPages.actions.update[10],
                          optionsPages.actions.update[11],
                          optionsPages.actions.delete[4],
                          optionsPages.actions.read[7],
                          optionsPages.actions.update[6],
                          optionsPages.actions.update[12]
                      ]
                  },
                  ...(verifyHideCountry() ? [pagesConfig[21]] : [])
              ]
          },
          {
              ...modulesPages[4],
              subNav: [pagesConfig[11], pagesConfig[12]]
          },
          {
              ...modulesPages[2],
              subNav: [
                  {
                      ...pagesConfig[7],
                      actions: [
                          optionsPages.actions.read[1],
                          optionsPages.actions.update[2],
                          optionsPages.actions.read[2],
                          optionsPages.actions.update[4],
                          optionsPages.actions.update[5],
                          optionsPages.actions.update[3],
                          optionsPages.actions.read[3],
                          optionsPages.actions.update[7],
                          optionsPages.actions.read[13]
                      ]
                  },
                  {
                      ...pagesConfig[8],
                      actions: [optionsPages.actions.read[5]]
                  },
                  {
                      ...pagesConfig[19],
                      actions: [optionsPages.actions.read[8]]
                  },
                  ...(verifyHideCountry2() ? [{
                      ...pagesConfig[22],
                      actions: [optionsPages.actions.read[12]]
                  }] : []),
              ]
          },
          {
              ...modulesPages[3],
              subNav: [pagesConfig[9], pagesConfig[10]]
          },
          {
              ...modulesPages[5],
              subNav: [
                  pagesConfig[16],
                  {
                      ...pagesConfig[17],
                      actions: [optionsPages.actions.read[4]]
                  }
              ]
          },
          {
              ...pagesConfig[18],
              actions: [
                  optionsPages.actions.read[10],
                  optionsPages.actions.update[13]
              ]
          },
          pagesConfig[13]
      ]
  },

  7: {
    idRole: 7,
    name: translate("global.typesRoleBli.7"),
    idTypeUser: 3,
    isAdmin: true,
    options: [
      pagesConfig[1],
      {
        ...modulesPages[1],
        subNav: [
          pagesConfig[2],
          {
            ...pagesConfig[3],
            actions: [
              optionsPages.actions.create[1],
              optionsPages.actions.search[2],
              optionsPages.actions.delete[2]
            ]
          },
          pagesConfig[4],
          pagesConfig[5],
          {
            ...pagesConfig[6],
            actions: [
              optionsPages.actions.search[1],
              optionsPages.actions.read[6],
              optionsPages.actions.update[8],
              optionsPages.actions.update[9],
              optionsPages.actions.update[10],
              optionsPages.actions.update[11],
              optionsPages.actions.delete[4],
              optionsPages.actions.read[7],
              optionsPages.actions.update[6],
              optionsPages.actions.update[12]
            ]
          }
        ]
      },
      {
        ...modulesPages[4],
        subNav: [pagesConfig[11], pagesConfig[12]]
      },
      {
        ...modulesPages[2],
        subNav: [
          {
            ...pagesConfig[7],
            actions: [
              optionsPages.actions.read[1],
              optionsPages.actions.update[2],
              optionsPages.actions.read[2],
              optionsPages.actions.read[3],
              optionsPages.actions.update[7],
              optionsPages.actions.read[6],
              optionsPages.actions.update[8],
              optionsPages.actions.update[9],
              optionsPages.actions.update[10],
              optionsPages.actions.delete[4],
              optionsPages.actions.read[7]
            ]
          },
          {
            ...pagesConfig[8],
            actions: [optionsPages.actions.read[5]]
          },
          ...(verifyHideCountry2() ? [{
              ...pagesConfig[22],
              actions: [optionsPages.actions.read[12]]
          }] : [])
        ]
      },
      {
        ...modulesPages[5],
        subNav: [
          pagesConfig[16],
          {
            ...pagesConfig[17],
            actions: [optionsPages.actions.read[4]]
          }
        ]
      },
      {
        ...modulesPages[3],
        subNav: [pagesConfig[9], pagesConfig[10]]
      },
      {
        ...pagesConfig[18],
        actions: [optionsPages.actions.read[10]]
      },
      pagesConfig[13]
    ]
  }
};

export const verifyAccessViewPermissions = (permissionNames) => {
  // Obtener el rol del usuario almacenado en sessionStorage
  const userRole = JSON.parse(sessionStorage.getItem("user")).roleId;

  const roleConfig = configRolesUsers[userRole];

  for (const permissionName of permissionNames) {
    let hasPermission = false;
    
    for (const option of roleConfig.options) {
     
      if (option.subNav) {
        for (const subPage of option.subNav) {
          if (subPage.actions && subPage.actions.includes(permissionName)) {
            hasPermission = true;
            break; 
          }
        }
      } else if (option.actions && option.actions.includes(permissionName)) {
        hasPermission = true;
        break; 
      }
    }

    if (!hasPermission) {
      return false;
    }
  }
  
  return true;
};