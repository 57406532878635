import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { getClientDomain, getToken, setPage, setValueSearchFilter, translate, setStatusObjTranslate } from '../../../../../utils/Common';
import AuthContext from '../../../../../contexts/auth';
// icons
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import { AiFillDashboard } from "react-icons/ai";

import ProviderList from '../../../../../components/ProviderList2';
import PlatformSearchList from '../../../../../components/PlatformSearchList';

// formulario de inicio para escribir parametros de busqueda
const FormSearch = (props) => {
    const userContext = useContext(AuthContext);
    const { register, errors, handleSubmit, setValue, reset, watch } = useForm();

    // para obtener los proveedores
    const [selectUsuarios, setSelectUsuarios] = useState(null);
    const [platformSelect, setPlatformSelect] = useState(null);
    const [provSelect, setProvSelect] = useState(null);
    const [dateSearchType, setDateSearchType] = useState(''); // Estado para el tipo de búsqueda de fecha
    const [dateOption, setDateOption] = useState([]); // Estado para la opción de fecha
    const [estatusBoxit, setEstatusBoxit] = useState([]);// para obtener status de boxit
    const isEstatusBoxitLoaded = useRef(false); // Para asegurarse de que los datos solo se carguen una vez

    const getDateSearchTypes = [
        { value: "1", label: translate("global.deliveryDate") },
        { value: "2", label: translate("global.pickupDate") },
        { value: "8", label: translate("global.returnDate") }
        // Agrega más opciones si es necesario
    ];

    const handleResetForm = () => {
        setPlatformSelect(null);
        setProvSelect(null);
        setDateSearchType(''); // Resetear el tipo de búsqueda de fecha
        reset();
    }

    useEffect(() => {
        const controller = new AbortController();
        setPage('');
        setValueSearchFilter('');

        const getEstatusBoxit = async () => {
            try {
                const response = await fetch(`${getClientDomain(2)}/dashboard/packages/estatus/boxit`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    signal: controller.signal
                });

                const result = await response.json();
                if (result) {
                    if (result.payload) {
                        let response = setStatusObjTranslate(result.payload)
                        setEstatusBoxit(response)
                    }
                    else setEstatusBoxit([]);
                }
            } catch (error) {
                setEstatusBoxit([]);
            }
        }
        getEstatusBoxit();
        // para obtener los proveedores
        const getProveedores = async () => {
            try {
                const response = await fetch(`${getClientDomain(2)}/users/tipoUsuario/all`, {
                    method: 'POST',
                    accept: 'application/json',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${getToken()}`
                    },
                    signal: controller.signal
                });

                const result = await response.json();
                // obtener los usuarios 
                if (result) {
                    if (result.payload) setSelectUsuarios(result.payload);
                    else setSelectUsuarios([]);
                }
            } catch (error) {
                setSelectUsuarios([]);
            }
        }

        setDateOption(getDateSearchTypes);

        userContext.user?.IsAdmin && getProveedores();

        return () => controller.abort();
    }, [userContext.user?.IsAdmin]);

    return (
        <form className="dashboardCard tracking-busqueda text-left" onSubmit={handleSubmit(props.onFindPackage)}>
            <div className="form-row text-left mt-0 mb-2">
                <span className="font-small">{translate("dashboard.packages.search.formSearch.description")}</span>
            </div>
            {/* Sección de búsqueda */}

            {/* Drop Off Code y Tracking Number */}
            <div className="form-row">
                <div className="form-group text-left col-12 col-md-6 mb-2">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputNumeroPaquete">
                                <FaIcons.FaCubes size={14} />
                            </span>
                        </div>
                        <input
                            type="text"
                            className={"form-control form-control-sm font-small" + (errors.numeroPaquete ? " is-invalid" : "")}
                            placeholder={translate("global.numPackage")}
                            id="inputNumeroPaquete"
                            name="numeroPaquete"
                            autoFocus
                            ref={register({
                                maxLength: {
                                    value: 32,
                                    message: translate("login.signup.validate.maxLength32")
                                }
                            })} />
                        {errors.numeroPaquete && <div className="invalid-feedback">{errors.numeroPaquete.message}</div>}
                    </div>
                </div>

                <div className="form-group text-left col-12 col-md-6 mb-2">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputNumeroTracking">
                                <MdIcons.MdTrackChanges size={14} />
                            </span>
                        </div>
                        <input
                            type="text"
                            className={"form-control form-control-sm font-small" + (errors.numeroTracking ? " is-invalid" : "")}
                            placeholder={translate("global.numTracking")}
                            id="inputNumeroTracking"
                            name="numeroTracking"
                            ref={register({
                                maxLength: {
                                    value: 32,
                                    message: translate("login.signup.validate.maxLength32")
                                }
                            })} />
                        {errors.numeroTracking && <div className="invalid-feedback">{errors.numeroTracking.message}</div>}
                    </div>
                </div>
            </div>

            {/* Nombre y Apellido Cliente */}
            <div className="form-row">
                <div className="form-group text-left col-12 col-md-6 mb-2">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputName">
                                <FaIcons.FaUser size={14} />
                            </span>
                        </div>
                        <input
                            type="text"
                            className={"form-control form-control-sm font-small" + (errors.nombre ? " is-invalid" : "")}
                            placeholder={translate("dashboard.packages.search.formSearch.nameClient")}
                            id="inputName"
                            name="nombre"
                            ref={register({
                                maxLength: {
                                    value: 32,
                                    message: translate("login.signup.validate.maxLength32")
                                }
                            })} />
                        {errors.nombre && <div className="invalid-feedback">{errors.nombre.message}</div>}
                    </div>
                </div>

                <div className="form-group text-left col-12 col-md-6 mb-2">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputApellido">
                                <FaIcons.FaUser size={14} />
                            </span>
                        </div>
                        <input
                            type="text"
                            className={"form-control form-control-sm font-small" + (errors.apellido ? " is-invalid" : "")}
                            placeholder={translate("dashboard.packages.search.formSearch.lastNameClient")}
                            id="inputApellido"
                            name="apellido"
                            ref={register({
                                maxLength: {
                                    value: 32,
                                    message: translate("login.signup.validate.maxLength32")
                                }
                            })} />
                        {errors.apellido && <div className="invalid-feedback">{errors.apellido.message}</div>}
                    </div>
                </div>
            </div>

            {/* Provider Select y Status Boxit Select */}
            <div className="form-row">
                <div className="form-group text-left col-12 col-md-6 mb-2">
                    <PlatformSearchList
                        register={register}
                        errors={errors}
                        setPlatformSelect={setPlatformSelect}
                        platfSelect={platformSelect}
                        valueId={"plataforma"}
                        readOnly={false}
                        setValue={setValue}
                    />
                </div>

                <div className="form-group text-left col-12 col-md-6 mb-2">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputName">
                                <AiFillDashboard size={16} />
                            </span>
                        </div>
                        <select
                            className="form-control form-control-md font-small"
                            id="selectEstatus"
                            name="estatusBoxit"
                            ref={register()}>
                            <option defaultValue>-- {translate("global.status")} Boxit --</option>
                            {estatusBoxit?.length > 0 && estatusBoxit.map((estatus, index) =>
                                <option
                                    value={estatus.IdEstatus}
                                    key={index}>
                                    {estatus.EstatusBoxit}
                                </option>)}
                        </select>
                    </div>
                    {errors.estatusBoxit && <div className="invalid-feedback">{errors.estatusBoxit.message}</div>}
                </div>
            </div>

            {/* Platform Select y Date type Select */}
            <div className="form-row">
                {userContext.user?.IsAdmin &&
                    <div className="form-group col-sm-12 col-md-6 mb-2 text-left">
                        <ProviderList
                            register={register}
                            nameShow={"-- " + translate("global.business") + " --"}
                            idShow={"all"}
                            readOnly={false}
                            setValue={setValue}
                            valueId={"usuario"}
                            provSelect={provSelect}
                            setProveedor={setProvSelect}
                        />
                        {errors.p && <span className="text-error">{errors.usuario.message}</span>}
                    </div>
                }

                {/* <div className="form-group text-left col-12 col-md-6 mb-2">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputDateSearchType">
                                <FaIcons.FaCalendarAlt size={14} />
                            </span>
                        </div>
                        <select
                            className="form-control form-control-md font-small"
                            id="selectDateSearchType"
                            name="tipo"
                            value={dateSearchType}
                            onChange={(e) => {
                                setDateSearchType(e.target.value);
                                setValue('tipo', e.target.value); // Actualiza el valor en react-hook-form
                            }}
                            ref={register()}
                        >
                            <option value="">-- {translate("global.select")} {translate("global.type")} {translate("global.date")} --</option>
                            {dateOption.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div> */}
            </div>

            <div className="form-row">
                {dateSearchType && (<>
                    <div className="form-group text-left col-12 col-md-6 mb-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputStartDate">
                                    <FaIcons.FaPlayCircle size={14} />
                                </span>
                            </div>
                            <input
                                type="text"
                                className={"form-control form-control-sm font-small" + (errors.fechaInicio ? " is-invalid" : "")}
                                id="inputStartDate"
                                name="fechaInicio"
                                placeholder={translate("global.startDate")}
                                onFocus={(e) => e.target.type = 'date'}
                                onBlur={(e) => e.target.type = 'text'}
                                ref={register({ required: dateSearchType !== '' })}
                                onChange={(e) => setValue('fechaInicio', e.target.value)}
                                required={dateSearchType ? true : false}
                            />
                            {errors.fechaInicio && <div className="invalid-feedback">{errors.startDate.message}</div>}
                        </div>
                    </div>
                    <div className="form-group text-left col-12 col-md-6 mb-2">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputEndDate">
                                    <FaIcons.FaStopCircle size={14} />
                                </span>
                            </div>
                            <input
                                type="text"
                                className={"form-control form-control-sm font-small" + (errors.endDate ? " is-invalid" : "")}
                                id="inputEndDate"
                                name="fechaFin"
                                placeholder={translate("global.endDate")}
                                onFocus={(e) => e.target.type = 'date'}
                                onBlur={(e) => e.target.type = 'text'}
                                ref={register({ required: dateSearchType !== '' })}
                                disabled={!watch('fechaInicio')}
                                required={dateSearchType ? true : false}
                            />
                            {errors.fechaFin && <div className="invalid-feedback">{errors.endDate.message}</div>}
                        </div>
                    </div>
                </>
                )}
            </div>

            <div className="form-row">
                <div className="form-group col-12 text-center mb-0">
                    <button className="btn btn-outline-fucsia mx-2 mt-2" type="reset" onClick={() => { handleResetForm() }}>{translate("global.clean")}</button>
                    <button className="btn btn-fucsia mx-2 mt-2" type="submit">{translate("global.search")}</button>
                </div>
            </div>
        </form>
    );
}

export default FormSearch;