import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { GiPlatform } from 'react-icons/gi';
import "./index.css";
import { translate } from '../../../../utils/Common';
import { FaColumns, FaDoorOpen, FaSave, FaEdit, FaTrashAlt, FaArrowCircleRight } from 'react-icons/fa';
import { MdLockOutline, MdOutlineSevereCold } from 'react-icons/md';
import { usePlatforms } from '../../../../hooks/usePlatforms';
import { FaComputer } from 'react-icons/fa6';
import Spinner from '../../../../components/Spinner';
import ModalContent from '../../../../components/ModalContent';
import BtnSync from '../../../../components/BtnSync';

const FormModulesLocker = (props) => {
    const { setOptionSelect, setConfigLocker, configLocker, externalId, settingsLocker, handleGetInfoPlatform, setEditModeModule, editModeModule} = props;
    const [isLoading, setIsLoading] = useState(false);
      const [modal, setModal] = useState({ title: '', body: '', footer: '' });
    const [showModal, setShowModal] = useState(false);
    const { createModulesPlatform, updateModulesPlatform } = usePlatforms();
    const [originalConfigLocker, setOriginalConfigLocker] = useState(null);

     // Obtener el último número de puerta creado
     const getLastDoorNumber = () => {
        let lastDoorNumber = 0;
        configLocker.modules.forEach(module => {
            module.columns.forEach(column => {
                column.doors.forEach(door => {
                    if (Number(door.doorNumber) > lastDoorNumber) { // Asegurarse de que sea número
                        lastDoorNumber = Number(door.doorNumber);
                    }
                });
            });
        });
        return lastDoorNumber;
    }

    // Crear un nuevo módulo
    const createRenderModule = () => {
        const lastDoorNumber = getLastDoorNumber();
        setConfigLocker({
            ...configLocker,
            modules: [...configLocker.modules, 
                {
                    moduleNumber: configLocker.modules.length + 1, 
                    numColumns: 2, 
                    numDoors: 20,
                    columns: [],
                    status: 1, // Estado inicial como borrador
                    startDoorNumber: lastDoorNumber + 1, // Almacenar el número inicial,
                    locationId: externalId,
                    moduleId: null
                }
            ]
        });
    }

 // Generar datos del módulo
 const addDataModuleLocker = (moduleId, data) => {
    const { totalDoors, columns, startDoorNumber } = data;

    const doorsPerColumn = Math.floor(totalDoors / columns);
    const remainder = totalDoors % columns;

    let result = {
        moduleNumber: moduleId,
        columns: []
    };

    let doorCounter = Number(startDoorNumber); // Asegurar número

    for (let i = 0; i < columns; i++) {
        let doorsInThisColumn = doorsPerColumn + (i < remainder ? 1 : 0);
        let column = {
            columnId: i + 1,
            doors: []
        };

        for (let j = 0; j < doorsInThisColumn; j++) {
            column.doors.push({
                doorNumber: doorCounter++,
                dimension: "S",
                layer: "H",
                doorSequence: j + 1,
                boxitDoorId: 0,
                columnModule: i + 1,
                id: uuidv4(),
                isActive: true,
                isScreen: false,
                locationId: externalId,
                moduleNumber: moduleId
            });
        }

        result.columns.push(column);
    }

    return result;
}

    // Actualizar los módulos
    const updateRenderModuleData = (index, data) => {
        const { numDoors, numColumns, isRefrigerated, isUnitCentral } = data;
        if (numDoors <= 0 || numColumns <= 0) {
             setModal({
                    title: translate( "global.alert.attention"),
                    body: 'Values must be greater than zero!',
                    footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
                  });
                  setShowModal(true);
            return;
        }

        if (isUnitCentral && hasCentralUnit() && !configLocker.modules[index].isUnitCentral) {
            setModal({
                title: translate( "global.alert.attention"),
                body: 'Ya existe una unidad central. Solo puede haber una unidad central.',
                footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
              });
              setShowModal(true);
            return;
        }

        const updatedModules = [...configLocker.modules];
        const moduleId = updatedModules[index].moduleNumber;
        const startDoorNumber = updatedModules[index].startDoorNumber || getLastDoorNumber() + 1;
        const dataSend = {
            numDoors: parseInt(numDoors), numColumns: parseInt(numColumns), startDoorNumber: startDoorNumber, totalDoors: numDoors, columns: numColumns
        }

        const moduleData = addDataModuleLocker(moduleId, dataSend);
        updatedModules[index] = {
            ...updatedModules[index],
            numDoors,
            numColumns,
            columns: moduleData.columns,
            status: 2, // Cambiar estado a editable,
            isRefrigerated,
            isUnitCentral
        };

        setConfigLocker({
            ...configLocker,
            modules: updatedModules
        });
    }

    const editModule = (index) => {
      const updatedModules = [...configLocker.modules];
      updatedModules[index].status = 1; // Estado editable
      setConfigLocker({
          ...configLocker,
          modules: updatedModules
      });
    }

    const deleteModule = (index) => {
        const updatedModules = configLocker.modules.filter((_, i) => i !== index);
        let doorCounter = 1;
  
        updatedModules.forEach((module, i) => {
            module.moduleNumber = i + 1; // Reordenar los números de los módulos
            module.startDoorNumber = doorCounter;
  
            module.columns.forEach(column => {
                column.doors.forEach(door => {
                    door.doorNumber = doorCounter++;
                });
            });
        });
  
        setConfigLocker({
            ...configLocker,
            modules: updatedModules
        });
    }

    const hasNonDraftModule = configLocker.modules.some(module => module.status === 2);
    const hasDraftModule = configLocker.modules.some(module => module.status === 1);
    const hasModuleInBd = configLocker.modules.some(module => module.status === 3);
    const hasCentralUnit = () => {
        return configLocker.modules.some(module => module.isUnitCentral);
    }   

    const getModuleDoorBoardData = (module, details) => {        

        const getPositionBoard = (moduleIndex) => { 
            // Verificar si el módulo actual es una unidad central
            if (configLocker.modules[moduleIndex]?.isUnitCentral) {
                return "C"; // Unidad central
            } else {
                // Determinar si está a la izquierda o derecha de la unidad central
                const centralIndex = configLocker.modules.findIndex(mod => mod.isUnitCentral); // Buscar unidad central
                if (centralIndex === -1) return ""; // No hay unidad central
                
                // Comparar los índices para determinar la posición
                return moduleIndex < centralIndex ? "L" : "R"; // Comparar el índice del módulo con el de la unidad central
            }
        };
        
        const defaultValue = {
            locationId: details.externalId,
            module: String(module.moduleNumber),
            doorQty: Number(module.numDoors),
            columnQty: Number(module.numColumns),
            position: getPositionBoard(module.indexModule),
            refrigerated: module.isRefrigerated,
            status: module.status
        }

        return defaultValue;
        
    };
    
    const handleSaveModulesPlatforms = async () => {
        
        if (!hasCentralUnit()) {
            setModal({
                title: translate( "global.alert.attention"),
                body: 'Debe haber al menos un módulo central para guardar los datos.',
                footer: <button className="btn btn-fucsia" onClick={() => setShowModal(false)}>{translate('global.return')}</button>,
              });
              setShowModal(true);
            return; // No continuar si no hay módulo central
        }
    
        setIsLoading(true);
        


    // Procesar solo los módulos filtrados
    const modules = (configLocker.modules.map((module, index) => {
        const response = getModuleDoorBoardData({...module, indexModule: index}, settingsLocker);
        return response;
    }));

           // Filtrar los módulos con status 2
    const filteredModules = modules.filter(module => module.status === 2);

    const dataToSend = {
        modules: filteredModules,
        settings: settingsLocker
    }

    const response = await createModulesPlatform(dataToSend);

    if (response?.code === 200 && response?.payload) {
        const createdModules = response.payload.map(item => ({
            id: item.boxitBoardId,
            module: item.moduleId,
            status: item.message
        }));

        setModal({
            title: translate( "global.alert.ready"),
            body: <>
                <p>
                    {translate("global.createSuccessModule")}:{" "}
                    {createdModules.map((module, index) => (
                        <span className="text-bold" key={index}>
                            {translate("global.module")} {module.module}
                            {index < createdModules.length - 1 && ", "}
                        </span>
                    ))}
                </p>               
            </>,
            footer: <button className="btn btn-fucsia" onClick={() =>  {
                setShowModal(false);
                setOptionSelect('doors'); 
            }}>{translate('global.ok')}</button>,
          });
        setShowModal(true); // Abrir el modal
       
    }

    setIsLoading(false);
    handleGetInfoPlatform(externalId);
    }

    const handleSelectEditModule = (module) => {
        setEditModeModule(true);
        // Cambiar de status de los módulos
        const updatedModules = [...configLocker.modules];
        updatedModules.forEach((mod, index) => {
            if (mod.moduleId === module.moduleId) {
                updatedModules[index].status = 4; // Estado actualizable
            } else {
                updatedModules[index].status = 3; // Estado bloqueado
            }
        });        

        setConfigLocker({
            ...configLocker,
            modules: updatedModules
        });
    }

    const handleUpdateModule = async (data) => {
        
        setIsLoading(true);

        const response = await updateModulesPlatform(data);

        if (response?.code===200 && response?.message === 'MODULE_UPDATED') {
            // mandar mensaje de actualización correcta
            setModal({
                title: translate( "global.alert.ready"),
                body: <>
                    <p>
                        {translate("global.updateSuccessModule")} <span className='text-bold'>{translate("global.module")} {data.module}</span>
                    </p>               
                </>,
                footer: <button className="btn btn-fucsia" onClick={() =>  {
                    setShowModal(false);
                }}>{translate('global.ok')}</button>,
              });
            setShowModal(true); // Abrir el modal

            // Actualizar el estado de los módulos
            const updatedModules = [...configLocker.modules];
            updatedModules.forEach((mod, index) => {
                if (mod.moduleId === data.moduleId) {
                    updatedModules[index].status = 3; // Estado bloqueado
                    updatedModules[index].numDoors = data.doorQty;
                    updatedModules[index].numColumns = data.columnQty;
                    updatedModules[index].isRefrigerated = data.refrigerated;
                    updatedModules[index].isUnitCentral = data.isUnitCentral;
                }
            });
            setConfigLocker({
                ...configLocker,
                modules: updatedModules
            });

            await handleGetInfoPlatform(externalId);
            
        }else{
            setModal({
                title: translate( "global.alert.error"),
                body: <>
                
                </>,
                footer: <button className="btn btn-fucsia" onClick={() =>  {
                    setShowModal(false);
                }}>{translate('global.ok')}</button>,
                });
            setShowModal(true); // Abrir el modal
            }
     
        setIsLoading(false);
    }
    
    const handleCancelSelectEditModule = () => {
            // Regresa a status 3 los módulos
            const updatedModules = [...configLocker.modules];
            updatedModules.forEach((mod, index) => {
                updatedModules[index].status = 3;
            });
            setConfigLocker({
                ...configLocker,
                modules: updatedModules
            });

            setEditModeModule(false);
           
    }
    
    
        useEffect(() => {
          if (editModeModule) {
            // Guarda los estados originales al entrar en modo edición
            setOriginalConfigLocker(JSON.parse(JSON.stringify(configLocker)));
          }
        }, [editModeModule]);

    return (
        <div className='cardInternal'>
             <ModalContent
                        title={modal.title}
                        body={modal.body}
                        footer={modal.footer}
                        isOpenModal={showModal}
                        onHide={() => setShowModal(false)}
                />
            <div className="cardInternal-header">
                <div className='cardInternal-title'>
                    <span className="mr-1"><GiPlatform size={20} /></span>
                    {translate( "global.modules")}
                </div>
                <div style={{ display:'flex', alignItems: 'center', gap: '5px'}}>

                </div>
                {
                    hasNonDraftModule && (
                      <div className="cardInternal-actions">
                        <button className="btn btn-blue btn-sm" onClick={() => handleSaveModulesPlatforms()} disabled={isLoading}>
                        {
                            isLoading ? <Spinner size={16} type={1}/> : <FaSave size={16} />
                        }  {translate("global.save")} 
                        </button>
                      </div>
                    )
                  }

{
    hasModuleInBd && (
<div>
                        <button className="btn btn-gray btn-sm" onClick={() => setOptionSelect('doors')}>
                            {translate("login.signup.btn.next")} <FaArrowCircleRight />
                        </button>
                  </div>
    )
}
                  
            </div>

            <div className="cardInternal-body">

                {
                    !editModeModule && (
                        <button className="btn-dashed" onClick={() => !hasDraftModule && createRenderModule()} disabled={hasDraftModule}>
                        + {translate("global.addModule")}
                    </button>
                    )
                }
               

                <div className="modules-list mt-2 p-2">
                    {configLocker.modules.map((module, index) => (
                        <div key={index} className="module-item">
                            <div className="module-header">
                                <div className="module-title">
                                    {translate("dashboard.platforms.module")} {module.moduleNumber}
                                </div>

                                <div className="module-actions">
                                    {module.status === 1 && (
                                        <button 
                                            className="btn btn-fucsia btn-sm d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                const numDoors = document.getElementById(`doors-${index}`).value;
                                                const numColumns = document.getElementById(`columns-${index}`).value;
                                                const isRefrigerated = document.getElementById(`switchCheckRefrigerated-${index}`)?.checked;
                                                const isUnitCentral = document.getElementById(`switchCheckUnitCentral-${index}`)?.checked || false;
                                                const dataSend = {numDoors, numColumns, isRefrigerated, isUnitCentral};
                                                updateRenderModuleData(index, dataSend);
                                            }}
                                        >
                                            + {translate("global.add")}
                                        </button>
                                    )}

{module.status === 2 && (
                                        <>
                                            <button 
                                                className="btn btn-fucsia btn-sm d-flex align-items-center justify-content-center"
                                                onClick={() => editModule(index)}
                                            >
                                                <FaEdit className='mr-1'/> {translate("global.edit")}
                                            </button>
                                            <button 
                                                className="btn btn-dashed btn-sm d-flex align-items-center justify-content-center"
                                                onClick={() => deleteModule(index)}
                                            >
                                                <FaTrashAlt />
                                            </button>
                                        </>
                                    )}

                                    <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                        
                                    {(module.status === 3 && module?.boardId > 0) && <BtnSync mode="module" size={"sm"} boardId={module?.boardId} /> }

                                    {(module.status === 3) && (
                                        <FaEdit size={18} className='icon-click' onClick={()=> {handleSelectEditModule(module)}}/>
                                    )}

                                    {module.status === 3 && (
                                        <MdLockOutline size={20} />
                                    )}                          
                                    </div>

                                    {module.status === 4 && (
                                        <div className='d-flex align-items-center' style={{gap: '10px'}}>
                                            <button
                                            className="btn btn-gray btn-sm d-flex align-items-center justify-content-center"
                                            onClick={()=>handleCancelSelectEditModule()}
                                            >
                                                 {translate("global.cancel")}
                                            </button>
 <button className='btn btn-fucsia btn-sm' onClick={() => 
                                        {
                                                const moduleId = module.moduleId;
                                                const numDoors = document.getElementById(`doors-${index}`).value;
                                                const numColumns = document.getElementById(`columns-${index}`).value;
                                                const isRefrigerated = document.getElementById(`switchCheckRefrigerated-${index}`)?.checked;
                                                const isUnitCentral = document.getElementById(`switchCheckUnitCentral-${index}`)?.checked || false;
                                                const dataSend = {...module, moduleId, doorQty: Number(numDoors), columnQty: Number(numColumns), refrigerated: isRefrigerated, isUnitCentral, module: module.moduleNumber};
                                                handleUpdateModule(dataSend);
                                        }} disabled={isLoading}>
                                             {
                            isLoading ? <Spinner size={16} type={1}/> : <FaSave size={14} />
                        }  {translate("global.save")} 
                                        </button>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="module-content">
                                {(module.status === 1 || module.status === 4) && (
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="text-label-title-dark">
                                                <FaDoorOpen className='mr-1'/>  {translate("global.Doors")}
                                            </label>
                                            <input 
                                                type="number" 
                                                id={`doors-${index}`} 
                                                className='form-control' 
                                                defaultValue={module.numDoors}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label className="text-label-title-dark">
                                                <FaColumns className='mr-1' /> {translate("global.columns")}
                                            </label>
                                            <select 
                                                id={`columns-${index}`} 
                                                className='form-control'
                                                defaultValue={module.numColumns}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                            </select>
                                        </div>
                                        
                                        <div className="col-6 mt-3">
                                             <div className="form-check form-switch ">
                                                <input className="form-check-input" type="checkbox" id={`switchCheckRefrigerated-${index}`}  defaultChecked={module.isRefrigerated}/>
                                                <label className="form-check-label" for={`switchCheckRefrigerated-${index}`} ><MdOutlineSevereCold className='mr-1' size={18} /> {translate("global.refrigerated")}</label>
                                             </div>
                                        </div>
                                        {
                                            (!hasCentralUnit() || module.isUnitCentral) && (
                                             <div className="col-6 mt-3">                                          
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id={`switchCheckUnitCentral-${index}`} defaultChecked={module.isUnitCentral} disabled={module.isUnitCentral}/>
                                                    <label className="form-check-label" for={`switchCheckUnitCentral-${index}`}><FaComputer className='mr-1' size={18}/>{translate("global.unitCentral")}</label>
                                                 </div>
                                            </div>
                                            )
                                        }
                                       
                                    </div>
                                )}

                                {(module.status !== 1 && module.status !== 4) && (
                                    <div style={{flexDirection: 'row', gap: '10px', display: 'flex', alignItems: 'center', color:"#c9c9c9"}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        <FaDoorOpen className='mr-1'/> <span className='text-bold mr-1'>{module.numDoors}</span> {translate("global.Doors")}
                                        </div>
                                        -
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        <FaColumns className='mr-1' /><span className='text-bold mr-1'>{module.numColumns}</span> {translate("global.columns")}
                                        </div>
                                        
                                        {module.isRefrigerated && (
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                 <span className='mr-1'>-</span> <MdOutlineSevereCold size={18} color="#42bcff" />
                                            </div>
                                        )}
                                       
                                        {module.isUnitCentral && (
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                              <span className='mr-1'>-</span> <FaComputer  size={18} color='#47fa7f'/>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FormModulesLocker;
