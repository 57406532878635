import React, { useRef, useState } from 'react';
import { translate } from '../../../../utils/Common';
import * as FaIcons from 'react-icons/fa';
import * as BiIcons from 'react-icons/bi';
import { Spinner } from 'react-bootstrap';
import './index.css';
import FormGenerate from './FormGenerate';
import QrCode from 'qrcode.react';
import { usePlatforms } from '../../../../hooks/usePlatforms';
import ModalContent from '../../../../components/ModalContent';

const GenerateQR = () => {
    const { getQrCodeAccess } = usePlatforms();
    const [loading, setLoading] = useState(false);
    const [qrData, setQrData] = useState(null);
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState({ title: '', body: '', footer: '' });
    const qrCodeRef = useRef();

    // Función para generar el QR
    const onGenerateQR = async (data) => {
        try {
            setLoading(true);
            const dataToSend = {
                ...data,
                matricula: data.matricula?.toString().trim().toUpperCase() || '0000',
                senha: data.senha?.toString().trim() || '1234',
            };

            const abortController = new AbortController();
            const response = await getQrCodeAccess(dataToSend, abortController.signal);
        
            if (response?.code === 200 && response?.payload[0]?.loginId !== null) {
                const retorno = response?.payload[0]?.Retorno;
                const successMessage = (
                    <>
                        <FaIcons.FaCheckCircle
                            color={retorno === 'Updated' ? 'green' : 'blue'}
                            style={{ margin: '1' }}
                        />
                        <p className="m-1 inline">
                            {translate('dashboard.platforms.generateQR.success')}
                        </p>
                    </>
                );
                setMessage(successMessage);
                setQrData(`${dataToSend.matricula};${dataToSend.senha}`);
            } else {
               
                setModal({
                    title: translate('global.alert.attention'),
                    body: translate('dashboard.platforms.generateQR.errorMessage'),
                    footer: <> 
                    <button className="btn btn-fucsia"
                     onClick={() => setShowModal(false)}>{translate('global.return')} 
                     </button>
                     
                     </>,     
                });
                setShowModal(true);
                setQrData(null);
            }
        } catch (error) {
            console.error(error);
            setModal({
                title: translate('global.error'),
                body: translate('dashboard.platforms.generateQR.errorMessage'),
                footer: <> 
                    <button className="btn btn-fucsia"
                     onClick={() => setShowModal(false)}>{translate('global.return')} 
                     </button>
                     
                     </>, 
            });
            setShowModal(true);
        } finally {
            setLoading(false);
        }
    };

    // Botón para regresar al formulario inicial
    const handleBack = () => setQrData(null);

       // Función para descargar el QR como imagen
       const handleDownloadQR = () => {
        const canvas = qrCodeRef.current.querySelector('canvas'); // Obtener el canvas del QR
        const url = canvas.toDataURL('image/png'); // Convertir a URL de imagen
        const link = document.createElement('a');
        link.href = url;
        link.download = 'qr-code.png'; // Nombre del archivo
        link.click();
    };


    return (
        <>
            <div className="dashboardCard text-left">
                <h4 className="m-0">
                    <FaIcons.FaQrcode className="mr-2" size={35} />
                    {translate('sidebar.options.platforms.GenerateQR')}
                </h4>
                <p className="mx-0 mb-0 mt-1">
                    {translate('dashboard.platforms.generateQR.description')}
                </p>
            </div>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="dashboardCard form-group">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">{translate('global.loading')}</span>
                        </Spinner>
                    </div>
                </div>
                
            ) : qrData ? (
                <>
                {/* Mostrar QR si existe qrData */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                            className="dashboardCard text-center mt-4 p-5"
                            style={{
                                width: 'fit-content',
                                borderRadius: '10px',
                                border: '1px solid #ddd',
                            }}
                        > 
                                    <div ref={qrCodeRef}>
                                        <QrCode value={qrData} size={200} />
                                    </div>
                                    <h4 className="mt-3">
                                        {translate('dashboard.platforms.generateQR.title')}
                                    </h4>
                                    <p
                                        className="m-0"
                                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                                    >
                                        {message}
                                    </p>
                                    <p className="mt-3" style={{ fontSize: '20px' }}>
                                        {translate(
                                            'dashboard.platforms.generateQR.formGenerate.title'
                                        )}
                                    </p>

                                    <div className="mt-2">
                                <button
                                    className="btn btn-fucsia mx-2"
                                    onClick={handleBack}
                                >
                                    <BiIcons.BiArrowBack size={15} className="mr-1" />
                                    {translate('global.return')}
                                </button>
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={handleDownloadQR}
                                >
                                    <FaIcons.FaDownload size={15} className="mr-1" />
                                    {translate('global.download')}
                                </button>
                            </div>
                        </div>
                    </div>
                 
                </>
            ) : (
                <FormGenerate onGenerateQR={onGenerateQR} />
            )}
               <ModalContent
                        title={modal.title}
                        body={modal.body}
                        footer={modal.footer}
                        isOpenModal={showModal}
                        onHide={() => setShowModal(false)}
                />
        </>
    );
};

export default GenerateQR;
