import { useState, useEffect } from "react";
import { usePlatforms } from "../../../hooks/usePlatforms";
import * as GiIcons from "react-icons/gi";
import PlatformDetails from "./PlatformDetails";
import ItemPlatform from "../../../components/ShowItems/ItemPlatform";
import ItemsInfo from "../../../components/ShowItems/ItemsInfo";
import SearchBoxItems from "../../../components/SearchBoxItems";
import ShowItems from "../../../components/ShowItems";
import { translate, verifyHideCountryItems } from "../../../utils/Common";
import Spinner from "../../../components/Spinner";

const General = () => {
  const [platformArray, setPlatformArray] = useState([]);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [platformSelected, setPlatformSelected] = useState(null);
  const { getAllPlatformsClient } = usePlatforms();
  const [itemsShowing, setItemsShowing] = useState(0);
  const [update, setUpdate] = useState(false);
  const onRefresh = () => setUpdate(!update);

  useEffect(() => {
    const abortController = new AbortController();

    try {
      const handlePlatforms = async () => {
        const platforms = await getAllPlatformsClient();
        platforms.sort((a, b) => (a.IdEstatus < b.IdEstatus ? 1 : -1));

        if (platforms.length > 0) {
          setItemsShowing(platforms.length <= 20 ? platforms.length : 20);
          setItemsFiltered(platforms);
          setPlatformArray(platforms);
        } else {
          setItemsShowing([]);
          setItemsFiltered([]);
          setPlatformArray([]);
        }
      };

      handlePlatforms();
    } catch (error) {
      setItemsShowing([]);
      setItemsFiltered([]);
      setPlatformArray([]);
      console.log(error);
    }
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  return (
    <>
      <div className="dashboardCard text-left d-flex justify-content-between align-items-center">
        <div>
          <h4 className="m-0">
            <GiIcons.GiPlatform className="mr-2" size={35} />
            {translate("dashboard.platforms.title")}
          </h4>
          <p className="mx-0 mb-0 mt-1">{translate("dashboard.platforms.description")}</p>
        </div>

{
  verifyHideCountryItems(["pa", "br"]) && (
<div>
          <button
            className="btn btn-blue"
            onClick={() => window.location = "/dashboard/platforms/settings?view=create"} c
          >
            + {translate("global.create")}
          </button>
        </div>
  )
}
        
       
      </div>

      {!platformSelected ? (
        <>
          <div className="dashboardCard">
            <ItemsInfo
              cantItemsFound={platformArray?.length}
              itemName={translate("global.platforms")}
            />

            <SearchBoxItems
              itemsArray={platformArray}
              itemsFound={itemsFiltered}
              setItemsShowing={setItemsShowing}
              setItemsFiltered={setItemsFiltered}
              filterType={"platform"}
            />
          </div>

        {platformArray && platformArray.length > 0 ? (
          <ShowItems
          ItemComponent={ItemPlatform}
          itemsArray={platformArray}
          itemsFound={itemsFiltered}
          setItemsFiltered={setItemsFiltered}
          itemName={translate("global.platforms")}
          showBox={true}
          setSelectedItem={setPlatformSelected}
          itemsShowing={itemsShowing}
          view={"grid"}
        />) : (
          <div className="loader center">
          <Spinner type={0} size={40} />
        </div>
        )
          }
          
        </>
      ) : (
        <PlatformDetails
          setPlatformSelected={setPlatformSelected}
          platformSelected={platformSelected}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
}

export default General;
