import React from 'react';
import './Skeleton.css'; // Archivo CSS para los estilos

const Skeleton = (props) => {
  // Configuración dinámica del estilo
  const { variant = 'rectangular', width = '100%', height = '20px', animation = 'wave', borderRadius = '4px' } = props;
  
  const styles = {
    width,
    height,
    borderRadius: variant === 'circular' ? '50%' : borderRadius, // Si es circular, poner 50% de border-radius
  };

  return (
    <div 
      className={`skeleton ${animation}`} 
      style={styles} 
    />
  );
};

export default Skeleton;
