import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { translate } from '../../../../../utils/Common';
import { Spinner } from 'react-bootstrap';
import { usePlatforms } from '../../../../../hooks/usePlatforms';
import PlatformSearchList from '../../../../../components/PlatformSearchList';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import ModalContent from '../../../../../components/ModalContent';

const FormGenerate = (props) => {
    const { getPlatformDetails } = usePlatforms();
    const { register, handleSubmit, errors, clearErrors, reset, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const [platformSelect, setPlatformSelect] = useState(null);
    const [platformDetails, setPlatformDetails] = useState(null);
    const [loadingPlatformDetails, setLoadingPlatformDetails] = useState(false); // Estado para la animación de carga
    const [modalTitle, setModalTitle] = useState('');
    const [body, setBody] = useState(null);
    const [footer, setFooter] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleResetForm = () => {
        clearErrors();
        reset();
        setPlatformSelect(null); // Limpia la selección de la plataforma
        setPlatformDetails(null); // Limpia los detalles de la plataforma
    }


    useEffect(() => {
        const abortController = new AbortController();
        const handlePlatform = async () => {
            if (platformSelect) {
                setLoadingPlatformDetails(true); // Inicia la animación de carga
                
                const response = await getPlatformDetails(
                    platformSelect.boxitLocationId,
                    abortController.signal
                );
                setPlatformDetails(response);
                setLoadingPlatformDetails(false); // Termina la animación de carga
            }
        };
        handlePlatform();
        return () => abortController.abort();
    }, [platformSelect]);

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            let cepLocker = platformDetails?.Address  || "" ; 
            if (cepLocker === "") {
                setIsOpenModal(true);
                setModalTitle(translate('global.alert.attention'));
                setBody(translate('dashboard.platforms.generateQR.formGenerate.errorAddress'));
                setFooter(
                    <button
                        type="button"
                        className="btn btn-sm
                        btn-fucsia"
                        onClick={() => setIsOpenModal(false)}
                    >
                        {translate('global.accept')}
                    </button>
                );
                setLoading(false);
                return;
            }

            await props.onGenerateQR({
                ...data,
                cepLocker,
            });
         
            setLoading(false);
        } catch (error) {
            console.error(error);
            // Abrir modal para mostrar errores
            setIsOpenModal(true);
            setModalTitle(translate("global.alert.attention"));
            setBody(translate("global.alerts.requestError"));
            setFooter(
                <button
                    type="button"
                    className="btn btn-sm btn-fucsia"
                    onClick={() => setIsOpenModal(false)}
                >
                    {translate("global.accept")}
                </button>
            );
        } 
    };
    
    return (
        <>
          <div className="form-container">
               <form className="form-container" onSubmit={handleSubmit(onSubmit)} >
             
             {!platformSelect && (
               <>
              <div className="dashboardCard tracking-busqueda text-left">
               <div className="form-row text-left mt-0 mb-2">
                    <span className="font-small">
                        {translate("global.selectPlatform")}
                    </span>
                </div>
                <div className="form-row">
                    
                        <div className="form-group text-left col-12 col-md-4 mb-2">
                            <PlatformSearchList
                                register={register}
                                errors={errors}
                                setPlatformSelect={setPlatformSelect}
                                platfSelect={platformSelect}
                                valueId={"plataforma"}
                                readOnly={false}
                                setValue={setValue}
                            />
                        </div>
              
                </div>
                </div>
                    </> 
                )}
             
            
              {loadingPlatformDetails ? (
                    <div className="form-row mb-0 p-0">
                        <div className="form-group col-12 text-center m-2">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">{translate("global.loading")}</span>
                            </Spinner>
                        </div>
                    </div>
                ) : ( 

                    platformDetails && (
                        <>
                    <div className='dashboardCard tracking-busqueda text-left'>
                        <div className="form-row mx-0 mb-1 border-bottom titleTransaccion"> 
                            <h5 className="ml-2 my-1">{translate("global.infoPlatform")}</h5>
                            </div>
                        <div className="form-row">
                            <div className="form-group col-12 platform-details text-left">
                                 
                                 <div className="form-row mx-0">
                                        <div className="col-12 pb-0 mb-0">
                                            <p><strong>{translate("global.name")}: </strong> {platformDetails?.Nombre ? platformDetails.Nombre : " "}</p>
                                        </div>
                                  </div>

                                    <div className="form-row mx-0">
                                        <div className="col-12 pb-0 mb-0">
                                            <p><strong>{translate("global.ubication")}: </strong> {platformDetails?.Direccion_Fisica ? platformDetails.Direccion_Fisica : ""}</p>
                                        </div>
                                    </div>

                                <div className="form-row mx-0">
                                    <div className="col-7 pb-0 mb-0">
                                        <p><strong>{translate("global.platformID")}: </strong> {platformDetails?.IdPlataforma ? platformDetails.IdPlataforma : ""}</p>
                                    </div>
                                </div>

                                <div className="form-row mx-0">
                                    <div className="col-7 pb-0 mb-0">
                                        <p><strong>{translate("global.ExternalID")}: </strong> {platformSelect?.locationId ? platformSelect.locationId : ""}</p>
                                    </div>
                                </div>

                                <div className="form-row mx-0">
                                    <div className="col-7 pb-0 mb-0">
                                        <p><strong>CEP: </strong> {platformDetails?.Address ? platformDetails.Address : ""}</p>
                                    </div>
                                </div>
                                    {/* Muestra más detalles según sea necesario */}
                              </div>
                            </div>
                            <div className="form-row text-left mt-0 mb-2">
                                <span className="font-small">
                                    {translate("dashboard.platforms.generateQR.formGenerate.description")}
                                </span>
                            </div>
                            <div className="form-row">
                                <div className="form-group text-left col-12 col-md-6 mb-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="matricula">
                                                <FaIcons.FaUser size={14} />
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className={"form-control form-control-sm font-small" + (errors.numeroPaquete ? " is-invalid" : "")}
                                            placeholder={translate("global.matricula")}
                                            id="matricula"
                                            name="matricula"
                                            autoFocus
                                            ref={register({
                                                maxLength: {
                                                    value: 32,
                                                    message: translate("login.signup.validate.maxLength32")
                                                }
                                            })}
                                        />
                                        {errors.numeroPaquete && <div className="invalid-feedback">{errors.numeroPaquete.message}</div>}
                                    </div>
                                </div>
                                
                                    <div className="form-group text-left col-12 col-md-6 mb-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                             <span className="input-group-text" id="senha">
                                                <MdIcons.MdPassword size={14} />
                                            </span>
                                        </div>
                                            <input
                                            type="password"
                                            className={"form-control form-control-sm font-small" + (errors.numeroTracking ? " is-invalid" : "")}
                                            placeholder={translate("global.senha")}
                                            id="senha"
                                            name="senha"
                                            ref={register({
                                                maxLength: {
                                                value: 32,
                                                message: translate("login.signup.validate.maxLength32")
                                                                }
                                                })} />
                                                {errors.numeroTracking && <div className="invalid-feedback">{errors.numeroTracking.message}</div>}
                                            </div>
                                        </div>          
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12 text-center mb-0">
                                    <button
                                        className="btn btn-outline-fucsia mx-2 mt-2"
                                        type="reset"
                                        onClick={handleResetForm}
                                    >
                                        {translate("global.clean")}
                                    </button>
                                    <button
                                        className={`btn btn-fucsia mx-2 mt-2`}
                                        type="submit"
                                        style={{ width: '120px' }}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <>
                                                <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                                                <span className="sr-only">{translate("sidebar.options.platforms.GenerateQR")}</span>
                                            </>
                                        ) : (
                                            <span>
                                                {translate("sidebar.options.platforms.GenerateQR")}
                                            </span>
                                        )}
                                    </button>
                                </div>
                           </div>
                        </div>
                          

                        </>
                    )
                )}
              
              </form>
          </div>
         
          <ModalContent
                title={modalTitle}
                body={body}
                footer={footer}
                isOpenModal={isOpenModal}
                onHide={() => setIsOpenModal(false)}
            />
          </>
    );
}

export default FormGenerate;