import React, { useState, useEffect, useRef, useContext } from 'react';
import { getClientDomain, getToken,translate, verifyHideCountryItems } from '../../../../utils/Common';
import AuthContext from '../../../../contexts/auth';
import ModalContext from '../../../../contexts/modal';
import QrCode from "qrcode.react";
// Icons
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import * as RiIcons from 'react-icons/ri';
import { verifyAccessViewPermissions } from '../../../../config/permissions';
import BtnSync from '../../../../components/BtnSync';

// menu de paquetes
const PackageOptionPanel = ({ selectedPackage, onClickPanelOption, optionSelected, handleCancelBusqueda, getInfoPaquete, isMobile, handleOpenModalChangeClient }) => {
    const userContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext);
    const userRole = JSON.parse(sessionStorage.getItem("user")).roleId;
    const idTipoLocker = selectedPackage?.IdTipoLocker ;
    const isAdmin = userContext.user?.IsAdmin;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // panel opciones del menu
    const menuPanelOption = useRef();
    useEffect(() => {
        const handlerClosePanelOption = (e) => !menuPanelOption.current.contains(e.target) && setIsMenuOpen(false);

        window.addEventListener('mousedown', handlerClosePanelOption);
        
        return () => window.removeEventListener('mousedown', handlerClosePanelOption);
    }, []);

    // Confirmacion de opciones
    const handlerConfirmation = (option) => {
        switch (option) {
            case "RemoveReservation":
                modalContext.confirm(
                    translate("global.alert.attention"),
                    <div className="p-0 mx-1">
                        <p className="m-2 p-0">{translate("dashboard.packages.details.optionPanel.removeReservation.cancelReservation")} <span className="font-bold">{translate("global.door")} {selectedPackage?.Identificacion}</span>
                        {translate("components.packagesReservation.confirmReservation.address")} <span className="font-bold">{selectedPackage?.Plataforma}</span>,
                        {translate("dashboard.packages.details.optionPanel.removeReservation.correspondingPackage")} <span className="font-bold">{selectedPackage?.Nro_Id}</span>.</p>
                        <p className="mb-1 p-0">{translate("global.question.continue")}</p>
                    </div>,
                    [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ () => removeReservation(selectedPackage) }>{translate("global.yes")}</button>,
                    <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.no")}</button> ],
                    true);
                break;
            case "MakeReservation":
                modalContext.confirm(
                    translate("global.alert.attention"),
                    <span>{translate("components.packagesReservation.confirmReservation.title")}
                        <span className="font-bold"> {selectedPackage?.Nro_Id}</span>{translate("components.packagesReservation.confirmReservation.address")} 
                        <span className="font-bold"> {selectedPackage?.Plataforma}</span> {translate("components.packagesReservation.confirmReservation.question")}
                    </span>,
                    [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ () => makeReservation(selectedPackage) }>{translate("global.yes")}</button>,
                    <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.no")}</button> ],
                    true);
                break;
            case "ForceDeliveryPackage":
                modalContext.confirm(
                    translate("global.alert.attention"),
                    <div className="p-0 mx-1">
                        <p className="m-0 p-0">{translate("global.aboutTo")} <span className="font-bold">{translate("dashboard.packages.details.optionPanel.process")}</span> {translate("dashboard.packages.details.optionPanel.deliveryPackageNum")} <span className="font-bold">{selectedPackage?.Nro_Id}</span>.</p>
                        <p className="mb-2 p-0">{translate("dashboard.packages.details.optionPanel.onceProcessed")} <span className="font-bold">{translate("dashboard.packages.details.optionPanel.wontAbleTo")}</span> {translate("dashboard.packages.details.optionPanel.cancelDelivery")}</p>
                        <p className="mb-1 p-0">{translate("global.question.continue")}</p>
                    </div>,
                    [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ () => forceDeliveryPackage(selectedPackage) }>{translate("global.yes")}</button>,
                    <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.no")}</button> ],
                    true);
                break;
            case "RemovePackage":
                modalContext.confirm(
                    translate("global.alert.attention"),
                    <div className="p-0 mx-1">
                        <p className="m-0 p-0">{translate("global.aboutTo")} <span className="font-bold">{translate("dashboard.packages.details.optionPanel.annul")}</span> {translate("dashboard.packages.details.optionPanel.packageNum")} <span className="font-bold">{selectedPackage?.Nro_Id}</span>.</p>
                        <p className="mb-2 p-0">{translate("dashboard.packages.details.optionPanel.onceAnull")} <span className="font-bold">{translate("dashboard.packages.details.optionPanel.wontAbleTo")}</span> {translate("dashboard.packages.details.optionPanel.restoreItAgain")}</p>
                        <p className="mb-1 p-0">{translate("global.question.continue")}</p>
                    </div>,
                    [<button key={1} type="button" className="btn btn-sm btn-fucsia" onClick={ () => removePackage(selectedPackage) }>{translate("global.yes")}</button>,
                    <button key={2} type="button" className="btn btn-sm btn-outline-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.no")}</button> ],
                    true);
                break;
                case "ChangeClient":
                        handleOpenModalChangeClient();
                    break;
            default:
                break;
        }
        onClickPanelOption(option);
    }

    // Manejar de reservaciones
    const makeReservation = async (paquete) => {
        try {
            let dataToSend = {};

            dataToSend.numeroIdentificacion = paquete.Nro_Id;
            dataToSend.clasificacion = paquete.Clasificacion;
            dataToSend.idPlataforma = paquete.IdPlataforma;
            dataToSend.alto = paquete.Alto;
            dataToSend.ancho = paquete.Ancho;
            dataToSend.largo = paquete.Largo;
            modalContext.setIsOpenModal(false);

            const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/doors/reservation/add` , {
                method: 'POST',
                connection: 'keep-alive',
                accept: 'application/json',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(dataToSend)
            });
            const result = await response.json();
            if (result) {
                if (result.code === 200) {
                    if (result.payload) {
                        if (result.payload[0].Codigo === "1") {
                            getInfoPaquete(paquete);
                            modalContext.confirm(translate("global.modal.allReady"), 
                                <div className="mx-2 p-0">
                                    <p className="m-0 p-0 font-md">{translate("global.infoReservation")}:</p>
                                    <p className="m-0 p-0">{translate("dashboard.packages.details.numPackage")}: <span className="font-bold">{paquete.Nro_Id}</span></p>
                                    <p className="m-0 p-0">{translate("global.doorAssign")}: <span className="font-bold">{result.payload[0].Locker}</span></p>
                                    <p className="m-0 p-0">{translate("global.ubication")}: <span className="font-bold">{paquete.Plataforma}</span></p>
                                </div>,
                                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                true);
                        }
                        else if (result.payload[0].Codigo === "2") 
                            modalContext.confirm(translate("global.alerts.ops"), 
                                <span>{translate("dashboard.packages.details.optionPanel.makeReservation.noDoorsAvailable")}</span>,
                                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                true);
                        else if (result.payload[0].Codigo === "3") 
                            modalContext.confirm(translate("global.alert.attention"), 
                                <span>{translate("dashboard.packages.details.optionPanel.makeReservation.sizePackage")}</span>,
                                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                true);
                        else if (result.payload[0].Codigo === "4") 
                            modalContext.confirm(translate("global.alert.attention"), 
                                <span>{translate("components.packagesReservation.NOT_DOORS_AVAILABLE")}</span>,
                                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                true);
                        else modalContext.confirm(translate("global.alert.attention"), 
                                <span>{translate("dashboard.packages.details.optionPanel.makeReservation.reservationActive")}</span>,
                                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                true);
                        
                    }
                }
                else if (result.error || result.code === 404 || result.code === 500) {
                    modalContext.confirm(translate("global.alerts.ops"), 
                        <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                        true);
                }
            }
        } catch (error) {
            console.log(error);
            modalContext.confirm(translate("global.alerts.ops"), 
                <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                true);
        }
    }
    const removeReservation = async (paquete) => {
        try {
            let dataToSend = {};

            dataToSend.idPaquete = paquete.IdPaquete;
            modalContext.setIsOpenModal(false);

            const response = await fetch(`${getClientDomain(2)}/dashboard/platforms/doors/reservation/remove` , {
                method: 'POST',
                connection: 'keep-alive',
                accept: 'application/json',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(dataToSend)
            });

            const result = await response.json();
            if (result) {
                if (!result.error) {
                    if (result.payload) {
                        if (result.payload[0].Codigo === "1") {
                            getInfoPaquete(paquete);
                            modalContext.confirm(translate("global.alert.ready"), 
                                        <span>{translate("dashboard.packages.details.optionPanel.removeReservation.package")} <span className="font-bold">{`(${paquete.Nro_Id})` }</span> {translate("dashboard.packages.details.optionPanel.removeReservation.successfuly")}.</span>,
                                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.OK")}</button>,
                                        true);
                        }
                    }
                }
                if (result.error || result.code === 404 || result.code === 500) {
                    modalContext.confirm(translate("global.alerts.ops"), 
                        <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                        true);
                }
            }
        } catch (error) {
            console.log(error);
            modalContext.confirm(translate("global.alerts.ops"), 
                <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                true);
        }
    }

    // Forzar {translate("global.delivery")} de paquete
    const forceDeliveryPackage = async (paquete) => {
        try {
            const abortController = new AbortController();
            let dataToSend = {};

            dataToSend.idPaquete = paquete.IdPaquete;
            dataToSend.idUsuario = paquete.IdUsuario;


            modalContext.setIsOpenModal(false);

            const response = await fetch(`${getClientDomain(2)}/dashboard/packages/forceDelivery` , {
                method: 'POST',
                connection: 'keep-alive',
                accept: 'application/json',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(dataToSend),
                signal: abortController.signal
            });
            const result = await response.json();
            if (result) {
                if (result.code === 200) {
                    if (result.payload) {
                        if (result.payload[0].Mensaje === 'PACKAGE_PROCESED') {
                            getInfoPaquete(paquete);
                            modalContext.confirm(translate("global.modal.allReady"), 
                                        <div className="mx-2 p-0">
                                            <p className="m-0 p-0">{translate("dashboard.packages.details.optionPanel.forceDeliveryPackage.package")} <span className="font-bold">{paquete.Nro_Id}</span> ha sido procesado correctamente.</p>
                                        </div>,
                                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                        true);
                        }else if (result.payload[0].Mensaje === "PLATFORM_INCORRECT") {
                            getInfoPaquete(paquete);
                            modalContext.confirm(translate("global.alerts.ops"), 
                                        <div className="mx-2 p-0">
                                            <p className="m-0 p-0">{translate("dashboard.packages.details.dropOff.receivePackage.PLATFORM_INCORRECT")}</p></div>,
                                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                        true);
                        }
                        else {
                            modalContext.confirm(translate("global.alerts.ops"), 
                                <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                true);
                        }
                    }
                }
                if (result.error || result.code === 404 || result.code === 500) 
                    modalContext.confirm(translate("global.alerts.ops"), 
                        <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                        true);
            }
        } catch (error) {
            console.log(error);
            modalContext.confirm(translate("global.alerts.ops"), 
                <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                true);
        }
    }
    
    // ANULAR un paquete
    const removePackage = async (paquete) => {
        try {
            modalContext.setIsOpenModal(false);

            const closeModal = () => {
                modalContext.setIsOpenModal(false);
                handleCancelBusqueda();
            }

            const response = await fetch(`${getClientDomain(2)}/dashboard/packages/delete/${paquete?.IdPaquete}` , {
                method: 'DELETE',
                connection: 'keep-alive',
                accept: 'application/json',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                }
            });

            const result = await response.json();
            if (result) {
                if (result.code === 200) {
                    if (result.payload) {
                        if (result.payload[0].Mensaje === 'PACKAGE_DELETED') {
                            modalContext.confirm(translate("global.alert.ready"), 
                                        <span>{translate("dashboard.packages.details.optionPanel.forceDeliveryPackage.package")} <span className="font-bold">{ paquete.Nro_Id }</span> {translate("dashboard.packages.details.optionPanel.forceDeliveryPackage.null")}.</span>,
                                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => closeModal() }>{translate("global.accept")}</button>,
                                        true);
                        }
                        else {
                            modalContext.confirm(translate("global.alert.somethingWrong"), 
                                        <span>{translate("dashboard.packages.details.optionPanel.forceDeliveryPackage.notNull")}.</span>,
                                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                                        true);
                        }
                    }
                }
                else if (result.error || result.code === 404 || result.code === 500) {
                    modalContext.confirm(translate("global.alerts.ops"), 
                        <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                        <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                        true);
                }
            }
        } catch (error) {
            console.log(error);
            modalContext.confirm(translate("global.alerts.ops"), 
                <span>{translate("dashboard.packages.details.optionPanel.makeReservation.error")}</span>,
                <button type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
                true);
        }
    }
    
    // ver qr code
    const handleShowQrCode = (paquete) => {
        const qrValue = paquete?.IdEstatus === 3 ? paquete?.Clave : paquete?.Nro_Id;
        modalContext.confirm(
            translate("global.codeQR"),
            <div className="p-0 mx-1 text-center">
                <QrCode id='packageQrCode' value={qrValue} renderAs={'canvas'} />
            </div>,
            <button key={2} type="button" className="btn btn-sm btn-fucsia" onClick={ () => modalContext.setIsOpenModal(false) }>{translate("global.accept")}</button>,
            true);
    }

    // const printQrCode = () => {
    //     const svgQrCode = document.getElementById('packageQrCode');
    //     const qrCodeDataUri = svgQrCode.toDataURL('image/jpg', 0.3);
    //     console.log(qrCodeDataUri)
    // }

    const renderRemovePackageOption = () => {
        const canRemovePackage = verifyAccessViewPermissions(["remove_package"]);
        const isStatusTwoOrLess = selectedPackage?.IdEstatus <= 2;
        const isStatusThree = selectedPackage?.IdEstatus === 3;
        const isUserRoleThreeOrMore = userRole >= 3;
        const isUserRoleThree = userRole === 3;        
    
        const shouldShowRemovePackageOption = () => {
            if (!canRemovePackage) return false;
    
            // Caso: el paquete está en estado 2 o menos y el usuario es admin
            if (isStatusTwoOrLess) {
                if (isAdmin) return true; 
                return true
            }
    
            // Caso: el paquete está en estado 3 ("ON BOXIT")
            if (isStatusThree) {
                if (isUserRoleThree && idTipoLocker === 1) return true; // Usuario tipo Airfacilty
                if (isUserRoleThreeOrMore) return true;
            }
    
            // Por defecto, no mostrar opción
            return false;
        };
    
        return shouldShowRemovePackageOption() ? (
            <li className="options pl-2 text-left p-3 m-1" 
                onClick={() => handlerConfirmation("RemovePackage")}>
                <FaIcons.FaTrashAlt size={15} />
                <span className="ml-2">
                    {translate("dashboard.packages.details.optionPanel.unpack")}
                </span>
            </li>
        ) : null;
    };

    const syncOptions = (
        <li className={ `options pl-2 text-left p-3 m-1` }
                    onClick={(e) => {
                        const btnSyncRef = document.querySelector(`#btn-sync`); // Seleccionamos el componente BtnSync
                        if (btnSyncRef) {
                          btnSyncRef.click(); 
                        }
                      }} 
                      >
                        <BtnSync size="sm" id="btn-sync" mode="package" packageId={selectedPackage?.IdPaquete} />
                        <span className="ml-2">SYNC</span>
                    </li>
    )

    const configOptionsPanel = {
            1 : (
                    verifyAccessViewPermissions(["view_qrcode_package"]) && 
                    <li className={ `options pl-2 text-left p-3 m-1 ${ optionSelected === 'ShowQrCode' ? 'option-active' : '' }` } 
                    onClick={ () => handleShowQrCode(selectedPackage) }>
                        <FaIcons.FaQrcode size={15} />
                        <span className="ml-2">{translate("global.codeQR")}</span>
                </li>
                )
            ,
            2 : (
                    verifyAccessViewPermissions(["update_change_info_package"]) && 
                        <li className={ `options pl-2 text-left p-3 m-1 ${ optionSelected === 'ChangeInfo' ? 'option-active' : '' }` } 
                            onClick={ () => onClickPanelOption(optionSelected === "ChangeInfo" ? "" : "ChangeInfo") } >
                                <BsIcons.BsInfoCircleFill size={15}/>
                                <span className="ml-2">{translate("dashboard.packages.details.optionPanel.modifyInformation")}</span>
                        </li> 
                )
            ,
            3: (     verifyAccessViewPermissions(["update_change_platform"]) && (selectedPackage?.IdEstatus !== 3 || isAdmin) &&
            <li className={ `options pl-2 text-left p-3 m-1 ${ optionSelected === 'ChangePlatform' ? 'option-active' : '' }` } 
                onClick={ () => onClickPanelOption(optionSelected === "ChangePlatform" ? "" : "ChangePlatform") } >
                    <FaIcons.FaExchangeAlt size={15} />
                    <span className="ml-2">{translate("dashboard.packages.details.optionPanel.changePlatform")}</span>
            </li> ),
            4 : (
                verifyAccessViewPermissions(["update_reservation_package"]) && 
                        selectedPackage?.IdPlataforma !== 2 && 
                            (selectedPackage?.Identificacion ? 
                                ( selectedPackage?.IdEstatus <= 3 &&
                                    <li className="options pl-2 text-left p-3 m-1" 
                                        onClick={ () => handlerConfirmation("RemoveReservation") }>
                                        <div>
                                            <BsIcons.BsXCircleFill size={15}/>
                                            <span className="ml-2">{translate("dashboard.packages.details.optionPanel.removeReservation")}</span>
                                        </div>
                                    </li>
                                )
                                :
                                <li className="options pl-2 text-left p-3 m-1" 
                                    onClick={ () => handlerConfirmation("MakeReservation") }>
                                    <div>
                                        <BsIcons.BsInboxesFill size={15}/> 
                                        <span className="ml-2">{translate("dashboard.packages.details.optionPanel.reservationDoor")}</span>
                                    </div>
                                </li>
                )
            ),
           5 : ( verifyAccessViewPermissions(["update_force_delivery_package"]) && ((selectedPackage?.IdEstatus === 2 && selectedPackage?.IdPlataformaDet) ||
           (selectedPackage?.IdEstatus === 1 && selectedPackage?.IdPlataforma === 2 )) &&
              <li className="options pl-2 text-left p-3 m-1" 
                  onClick={ () => handlerConfirmation("ForceDeliveryPackage") } >
                      <FaIcons.FaCheckCircle size={15}/>
                      <span className="ml-2">{translate("dashboard.packages.details.optionPanel.process")} {translate("global.delivery")}</span>
              </li> ),
            6 : renderRemovePackageOption(),
            7 : (
                verifyAccessViewPermissions(["update_package_expiration"]) &&
                        (  <li className={ `options pl-2 text-left p-3 m-1 ${ optionSelected === "ChangeDateExpiration" ? 'option-active' : '' }` } 
                        onClick={ () => onClickPanelOption(optionSelected === "ChangeDateExpiration" ? "" : "ChangeDateExpiration") } >
                            <BsIcons.BsClockHistory size={15}/>
                            <span className="ml-2">{translate("global.updateDateExpiration")}</span>
                    </li>)
            ),
            8 : (
                verifyAccessViewPermissions(["update_change_client"]) && isAdmin && verifyHideCountryItems(["br"]) &&
                        (  <li className={ `options pl-2 text-left p-3 m-1 ` } onClick={ () => handlerConfirmation("ChangeClient") } >
                            <BsIcons.BsPeopleFill  size={15}/>
                            <span className="ml-2">{translate("global.changeClient")}</span>
                    </li>)
            ),
            9 : (
                (verifyHideCountryItems(["pa"]) && userRole >= 5) && (syncOptions)
            )
       
    };

    const configStatusPackage = {
        1 : (<>{configOptionsPanel[3]}{configOptionsPanel[1]}{configOptionsPanel[2]}{configOptionsPanel[4]}{configOptionsPanel[6]}{configOptionsPanel[7]}{configOptionsPanel[8]}{configOptionsPanel[9]}</>), // 1. NEW
        2 : (<>{configOptionsPanel[3]}{configOptionsPanel[1]}{configOptionsPanel[2]}{configOptionsPanel[4]}{configOptionsPanel[5]}{configOptionsPanel[6]}{configOptionsPanel[7]}{configOptionsPanel[8]}{configOptionsPanel[9]}</>), // 2. RESERVED DOOR
        3 : (<>{configOptionsPanel[3]}{configOptionsPanel[1]}{configOptionsPanel[2]}{configOptionsPanel[7]}{configOptionsPanel[6]}{configOptionsPanel[8]}{configOptionsPanel[9]}</>), // 3. ON BOXIT
        4 : (<>{configOptionsPanel[9]}</>), // 4. RETIRED
        5 : (<>{configOptionsPanel[3]}{configOptionsPanel[1]}{configOptionsPanel[2]}{configOptionsPanel[7]}{configOptionsPanel[6]}{configOptionsPanel[9]}</>), // 5. RETURNED
        6 : (<>{configOptionsPanel[9]}</>), // 6. ANULLED
        7 : (<>{configOptionsPanel[3]}{configOptionsPanel[1]}{configOptionsPanel[2]}{configOptionsPanel[7]}{configOptionsPanel[9]}</>), // 7. IN TRANSIT
          
    }

    return  <>
                <div className="pl-3 text-left p-2 m-1">
                    <h6>{translate("dashboard.packages.details.optionPanel.title")}</h6>
                </div>
                
                <div className={ "elegir-opcion " + (isMenuOpen ? "panel-active" : "") } ref={ menuPanelOption }>
                    { isMobile && 
                        <div className="options pl-2 text-left p-3 m-1" onClick={ () => setIsMenuOpen(!isMenuOpen) }>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <FaIcons.FaUsers size={16} /><span className="ml-2">{translate("global.chooseOption")}</span>
                                </div>
                                { isMenuOpen ?
                                    <RiIcons.RiArrowUpSFill size={16}/>
                                    :
                                    <RiIcons.RiArrowDownSFill size={16}/>
                                }
                            </div>
                        </div>
                    }

                    <ul className={ isMobile ? "dropdown-menu" : "" } onClick={ isMobile ? () => setIsMenuOpen(false) : null }>
                    
                        {configStatusPackage[selectedPackage?.IdEstatus]}
                       
                    </ul>
                </div>
            </>
}

export default PackageOptionPanel;
