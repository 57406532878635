import React, { useEffect, useState } from 'react';
import { FaSyncAlt, FaCheck, FaTimes } from 'react-icons/fa'; // Importamos los íconos
import './index.css';
import { usePlatforms } from '../../hooks/usePlatforms';
import { usePackages } from '../../hooks/usePackages';

const BtnSync = (props) => {
  const { mode, platformId, settingId, boardId, doorId, locationId, packageId, size='md', variant = 'btn', id='', setLoading } = props;
  const { syncLocationToLocker, syncSettingsToLocker, syncModuleToLocker, syncDoorToLocker, forceSynchronization } = usePlatforms();
  const {syncPackageToLocker} = usePackages();
  const [status, setStatus] = useState('idle'); // 'idle', 'success', 'error', 'loading'
  const [timer, setTimer] = useState(0);

  const handleClick = async () => {
    setStatus('loading');
    setTimer(0);

    try {
      setLoading && setLoading(true);
      const response = await handleSync();
      
      if (response?.code === 200) {
        setStatus('success'); // Muestra el check verde
      } else {
        setStatus('error'); // Muestra la X roja
      }

      setTimeout(() => {
        setLoading && setLoading(false);
      }, 3000);

    } catch (error) {
      console.error("Error durante la sincronización:", error);
      setStatus('error'); // En caso de error, también muestra la X roja
    } finally {
      // Vuelve al estado inicial después de 3 segundos
      setTimeout(() => {
        setStatus('idle');
        setTimer(0);
        setLoading && setLoading(false);
      }, 3000);
    }
  };

  const handleSync = async () => {
    switch (mode) {
      case "general":
        return syncLocationToLocker({ platformId });
      case "settings":
        return syncSettingsToLocker({ settingId });
      case "module":
        return syncModuleToLocker({ boardId });
      case "door":
        return syncDoorToLocker({ doorId });
        case "force":
          return forceSynchronization({ locationId });
        case "package":
          return syncPackageToLocker({ packageId });

      default:
        console.warn(`Modo no reconocido: ${mode}`);
        return Promise.resolve();
    }
  };

  const renderIcon = () => {
    switch (status) {
      case 'loading':
        return <FaSyncAlt className={`icon spinning ${size}`} />;
      case 'success':
        return <FaCheck className={`icon success ${size}`} />;
      case 'error':
        return <FaTimes className={`icon error ${size}`} />;
      default:
        return <FaSyncAlt className={`icon ${size}`} />;
    }
  };

   // Efecto para manejar el temporizador
   useEffect(() => {
    let interval = null;

    if (status === 'loading' && variant === 'timer') {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000); // Incrementa cada segundo
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [status, variant]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs.toString().padStart(2, '0')}`; // Formato MM:SS
  };

  return (
    <div
      className={`btn-sync ${size} ${status} ${variant}`}
      onClick={handleClick}
      title={`Sync ${mode}`} // Información adicional para el usuario
      id={id}
    >
      {variant === 'btn' && renderIcon()}
      {variant === 'timer' && (
        <div className="timer-wrapper">
          <FaSyncAlt className={`icon success ${size}`} />
          <span className="timer-text">{formatTime(timer)}</span>
        </div>
      )}
    </div>
  );
};

export default BtnSync;
