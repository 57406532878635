import React, { useEffect, useState } from 'react'
import { FaDoorOpen, FaFileAlt, FaTools } from 'react-icons/fa';
import { GiPlatform } from 'react-icons/gi';
import FormGeneralLocker from './FormGeneralLocker';
import PreviewLocker from './PreviewLocker/index';
import FormSettingsLocker from './FormSettingsLocker';
import FormModulesLocker from './FormModulesLocker';
import FormDoorsLocker from './FormDoorsLocker';
import "./index.css";
import { usePlatforms } from '../../../../hooks/usePlatforms';
import { translate } from '../../../../utils/Common';
import { v4 as uuidv4 } from 'uuid';

const configSelector = {
    general: {
        title: translate("sidebar.options.platforms.general"),
        icon: <FaFileAlt />,
        components: (props) => <FormGeneralLocker {...props} />,
    },
    settings: {
        title: translate("global.configuration"),
        icon: <FaTools />,
        components: (props) => <FormSettingsLocker {...props} />,
    },
    modules: {
        title: translate("global.modules"),
        icon: <GiPlatform size={20} />, 
        components: (props) => <FormModulesLocker {...props} />,
    },
    doors: {
        title: translate("global.Doors"),
        icon: <FaDoorOpen size={18} />, 
        components: (props) => <FormDoorsLocker {...props} />,
    }
};

const HeaderSelector = ({option, setOption, externalId}) => {

    return (
        <div className='headerSelector mt-4'>
            {Object.keys(configSelector).map((key, index) => (
                <div className='headerWrapper' key={key}>
                    {index > 0 && <div className='headerSeparator'> &gt; </div>}
                    <div 
                        className={`headerOption ${option === key ? 'headerOption-active' : ''}`} 
                        onClick={() => externalId && setOption(key)}
                    >
                        <span className='mr-1'>{configSelector[key].icon}</span> {configSelector[key].title}
                    </div>
                </div>
            ))}
        </div>
    );
};

const GroupFormCreate = ({setModulesIsCompleted, settingsLocker, setSettingsLocker }) => {
     const { getPlatformDetailsByExternalId, getDoorsByPlatform, getModulesByPlatform, getProviderPlatforms } = usePlatforms();
     const [platformDetails, setPlatformDetails] = useState(null);          

    const queryParams = new URLSearchParams(window.location.search);
    const extIdParams = queryParams.get('extId');
    const typeView = queryParams.get('view');
    const optParams = queryParams.get('opt') || 'general';
    
    const [optionSelect, setOptionSelect] = useState(optParams);
    const [selectDoor, setSelectDoor] = useState(null);
    const [externalId, setExternalId] = useState(extIdParams);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDoor, setIsLoadingDoor] = useState(false);
    const [configLocker, setConfigLocker] = useState({
        modules: [],
        settings: {},
    });
     const [optionsProviders, setOptionsProviders] = useState([]);
    const [doorsInBd, setDoorsInBd] = useState([]);
    const [editModeModule, setEditModeModule] = useState(false);  
    const [editModeDoors, setEditModeDoors] = useState(false);  

    const handleOptionChange = (value) => {
        setOptionSelect(value);
        if (value) {
            // Agregar el parámetro extId en la URL
           const url = new URL(window.location.href);
           url.searchParams.set('opt', value);
           window.history.pushState({}, '', url); 
        }
           
    }
    
    const handleGetInfoPlatform = async () => {
        setIsLoading(true);
        const abortController = new AbortController();
        if (extIdParams) {
            
            const response = await getPlatformDetailsByExternalId(extIdParams, abortController.signal);
            
            if (!response) {
                const url = new URL(window.location.href);
                url.searchParams.set('view', 'create');
                
                window.history.pushState({}, '', url); 
                // Eliminar el parámetro "extId"
                url.searchParams.delete('extId');
                setOptionSelect('general');

                // Actualizar la URL recargarndo la página
                // window.history.replaceState(null, '', url.toString());
            }else{
                const url = new URL(window.location.href);
                url.searchParams.set('view', 'edit');
                window.history.pushState({}, '', url); 
            }

            setExternalId(response?.externalId);
            setPlatformDetails(response);
            setConfigLocker({
                ...configLocker,
                settings: response || {},
            });
            setSettingsLocker(response);
            setIsLoading(false);
            await handleGetDoors();
            setIsLoadingDoor(false);
        }
    };    

    const replaceDoors = async (modules, doorsFromDB) => {
        // Iteramos sobre cada módulo generado
        modules.forEach(module => {
            // Filtramos las puertas de la base de datos que pertenecen a este módulo
            const doorsForModule = doorsFromDB.filter(door => door.moduleId === module.moduleId);
            
            // Iteramos sobre las columnas del módulo
            module.columns.forEach(column => {
                // Iteramos sobre las puertas de cada columna
                column.doors.forEach(door => {
                    // Buscamos una puerta en la base de datos que coincida en doorSequence
                    const matchingDoor = doorsForModule.find(dbDoor => dbDoor.doorSequence === door.doorSequence);
                    
                    // Si encontramos una coincidencia, actualizamos las propiedades de la puerta
                    if (matchingDoor) {
                        Object.assign(door, {
                            boxitDoorId: matchingDoor.boxitDoorId,
                            dimension: matchingDoor.dimension,
                            isScreen: matchingDoor.isScreen,
                            isActive: matchingDoor.isActive,
                            layer: matchingDoor.layer,
                            columnModule: matchingDoor.columnModule,
                            doorNumber: matchingDoor.doorNumber
                        });
                    }
                });
            });
        });
    
        return modules;
    }

    const reorganizeAndFixDoors = async (moduleCompleteGenerate, doorsFromDB) => {
        // Reorganizar las puertas en los módulos
        const reorganizedModules = await reorganizeDoors(moduleCompleteGenerate, doorsFromDB);
    
        // Corregir las puertas dentro de cada módulo
        for (const module of reorganizedModules) {
            fixDoorsInModule(module);
        }
    
        return reorganizedModules;
    };
    

    const reorganizeDoors = async (moduleCompleteGenerate, doorsFromDB) => {
        for (const door of doorsFromDB) {
            const { moduleId, columnModule, doorSequence } = door;
    
            // Encuentra el módulo correspondiente
            const module = moduleCompleteGenerate.find(mod => mod.moduleId === moduleId);
            if (!module) continue;
    
            // Encuentra la columna correspondiente
            const column = module.columns.find(col => col.columnId === columnModule);
            if (column) {
                // Verifica si la puerta ya está en la columna
                const existingDoor = column.doors.find(d => d.doorSequence === doorSequence);
                if (!existingDoor) {
                    column.doors.push(door); // Agrega la puerta si no existe
                } else {
                    // Puedes manejar casos específicos aquí si es necesario
                }
    
                // Ordena las puertas de la columna por `doorSequence` (menor a mayor)
                column.doors.sort((a, b) => a.doorSequence - b.doorSequence);
            }
    
            // Verifica si la puerta está en la columna y posición correctas
            const existingDoorIndex = column.doors.findIndex(d => d.doorSequence === doorSequence);
            if (existingDoorIndex === -1) {
                // Si no está en la posición correcta, mover la puerta
                removeDoorFromOtherColumns(module.columns, door.id); // Función para eliminar la puerta de otras columnas
                column.doors.push(door); // Agregar la puerta a la columna correcta
                column.doors.sort((a, b) => a.doorSequence - b.doorSequence); // Asegura el orden nuevamente
            }
        }
    
        // Validar que no haya más puertas que `numDoors` por módulo
        for (const module of moduleCompleteGenerate) {
            const totalDoors = module.columns.reduce((sum, col) => sum + col.doors.length, 0);
            if (totalDoors > module.numDoors) {
                // console.error(`Exceso de puertas en el módulo ${module.moduleNumber}`);
            }
        }
    
        // Retornar los módulos reorganizados
        return moduleCompleteGenerate;
    };    

          // Función para corregir las puertas en el módulo
    const fixDoorsInModule = (module) => {
            
            if (!module || !module.columns) {
                return;
            }
        
            // Recorremos cada columna
            module.columns.forEach(column => {
                // Filtramos las puertas que no corresponden al columnModule
                column.doors = column.doors.filter(door => {
                    const isCorrectColumn = door.columnModule === column.columnId;
                    if (!isCorrectColumn) {
                    }
                    return isCorrectColumn;
                });
            });
            
        }
    

    const removeDoorFromOtherColumns =(columns, doorId) => {
        for (const column of columns) {
            column.doors = column.doors.filter(door => door.id !== doorId);
        }
    }

    const handleGetDoors = async () => {
        // 1. Obtén los módulos primero.
        const responseModule = await handleGetModules();      
    
        // 2. Ahora obtenemos las puertas desde la base de datos.
        const abortController = new AbortController();
        const doorsInBd = await getDoorsByPlatform(externalId, abortController.signal);
        
        setDoorsInBd(doorsInBd);

        const updateModules = await replaceDoors(responseModule.modules, doorsInBd);
        
    const test = await reorganizeAndFixDoors(responseModule.modules, doorsInBd);
    
        
        setConfigLocker({...responseModule, modules: test});

    };    
    
    const groupModules = async (modules) => {
        // Verificar si modules es inválido o está vacío
        if (!modules || modules.length === 0) return [];
    
        // Ordenar los módulos por `moduleNumber` antes de procesarlos
        modules.sort((a, b) => a.moduleNumber - b.moduleNumber);
    
        const groupedModules = [];
        let sequenceCounter = 1; // Contador para numeración continua
    
        modules.forEach((module) => {
            let existingModule = groupedModules.find(m => m.moduleNumber === module.moduleNumber);
    
            if (!existingModule) {
                existingModule = {
                    ...module,
                    status: 3,
                    columns: [],
                    isUnitCentral: !!module.isUnitCentral,
                    isRefrigerated: !!module.isRefrigerated,
                    moduleId: module.moduleId[0],
                    startDoorNumber: null, // Secuencia inicial del módulo
                    endDoorNumber: null    // Secuencia final del módulo
                };
                groupedModules.push(existingModule);
            }
    
            const totalDoors = parseInt(module.numDoors, 10);
            const numColumns = parseInt(module.numColumns, 10);
    
            // Inicializar contador de secuencia para puertas del módulo actual
            let moduleDoorSequence = 1;
            let moduleStartSequence = sequenceCounter; // Guardar el inicio de la secuencia
    
            // Distribuir las puertas equitativamente entre las columnas
            let doorsRemaining = totalDoors; // Puertas restantes para distribuir
    
            for (let i = 0; i < numColumns; i++) {
                const doorsInThisColumn = Math.ceil(doorsRemaining / (numColumns - i)); // Distribuir puertas restantes
                const column = {
                    columnId: i + 1,
                    doors: []
                };
    
                for (let j = 0; j < doorsInThisColumn; j++) {
                    column.doors.push({
                        doorNumber: sequenceCounter++, // Asignar números de puerta de forma continua
                        dimension: module.dimension || "S",
                        layer: module.layer || "H",
                        isScreen: module.isScreen || false,
                        locationId: module.locationId,
                        moduleId: module.moduleId[0],
                        columnModule: i + 1,
                        doorSequence: moduleDoorSequence++,
                        moduleNumber: module.moduleNumber,
                        isActive: true,
                        id: uuidv4(),
                        boxitDoorId: 0
                    });
                }
    
                // Ordenar las puertas de la columna por `doorSequence`
                column.doors.sort((a, b) => a.doorSequence - b.doorSequence);
    
                existingModule.columns.push(column);
                doorsRemaining -= doorsInThisColumn; // Restar puertas asignadas
            }
    
            // Asignar los valores inicial y final de la secuencia al módulo
            existingModule.startDoorNumber = moduleStartSequence;
            existingModule.endDoorNumber = sequenceCounter - 1;
        });
    
        return groupedModules;
    };
    
      const handleGetModules = async () => {
        const abortController = new AbortController();
        const response = await getModulesByPlatform(externalId, abortController.signal);
        
        const grouped = await groupModules(response);  
          
        const result = {...configLocker, modules: grouped};
        return result;
      };


    const groupDoors = async (doorArray) => {
        if (doorArray?.length === 0) return [];
        const groupedDoors = {};
        doorArray?.forEach(door => {
            const key = `${door.boxitDoorId}`;
            if (!groupedDoors[key]) {
                groupedDoors[key] = {
                    locationId: door.locationId,
                    moduleId: door.moduleId,
                    boxitLocationId: door.boxitLocationId,
                    boxitModuleId: door.boxitModuleId,
                    boxitDoorId: door.boxitDoorId,
                    doorNumber: door.doorNumber,
                    dimension: door.dimension,
                    comPort: door.comPort,
                    createdAt: door.createdAt,
                    doorSequence: door.doorSequence,
                    isActive: door.isActive,
                    isAvailable: door.isAvailable,
                    isScreen: door.isScreen,
                    columnModule: door.columnModule,
                    layer: door.layer,
                    packageStatusId: door.packageStatusId,
                    moduleNumber: Number(door.moduleNumber),
                    packages: [],
                    isRefrigerated: door.isRefrigerated,
                    isUnitCentral: door.isUnitCentral
                };
            }
            if (groupedDoors[key].packages.length > 0 || door.packageId) {
                groupedDoors[key].packages.push({
                    packageId: door.packageId,
                    packageNumber: door.packageNumber,
                });
            }
        });
    
        const groupedDoorsArray = Object.values(groupedDoors);
    
        // Ordenamos por moduleNumber y luego por doorNumber
        const doorsOrdenate = groupedDoorsArray.sort((a, b) => {
            // Comparar por moduleNumber
            if (a.moduleNumber !== b.moduleNumber) {
                return a.moduleNumber - b.moduleNumber;
            }
            // Si moduleNumber es igual, comparar por doorNumber (conversión a número para evitar problemas)
            return Number(a.doorNumber) - Number(b.doorNumber);
        });
    
        return doorsOrdenate;
    };

      const getProvidersPlatforms = async () => {
        const response = await getProviderPlatforms();
        
        setOptionsProviders(response);
      }
    
    useEffect(() => {
        if (externalId) {
            handleGetInfoPlatform(externalId);
        }else{
            const url = new URL(window.location.href);
            url.searchParams.set('view', 'create');
            url.searchParams.set('opt', 'general');
            window.history.pushState({}, '', url); 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalId]); 
    
    
      useEffect(() => {
          getProvidersPlatforms();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

  return (
    <>
        <HeaderSelector option={optionSelect} setOption={handleOptionChange} externalId={externalId} />

        <div className='row mt-4'>

            <div className='col-lg-4 col-md-12'>
                {configSelector[optionSelect].components({ optionSelect, configLocker: configLocker, setOptionSelect: handleOptionChange, externalId: externalId, dataDetails: platformDetails, setDataDetails: setPlatformDetails, setExternalId, isLoadingData: isLoading, setConfigLocker: setConfigLocker, settingsLocker: settingsLocker, setSettingsLocker: setSettingsLocker, handleGetInfoPlatform: handleGetInfoPlatform, selectDoor: selectDoor, setSelectDoor: setSelectDoor, doorsInBd: doorsInBd, setDoorsInBd: setDoorsInBd, isLoadingDoor: isLoadingDoor, setIsLoadingDoor: setIsLoadingDoor, groupDoors: groupDoors, handleGetDoors: handleGetDoors, handleGetModules: handleGetModules, typeView: typeView, optionsProviders: optionsProviders, setEditModeModule: setEditModeModule, editModeModule: editModeModule, setEditModeDoors: setEditModeDoors, editModeDoors: editModeDoors})}
            </div>

            <div className='col-lg-8 col-md-12'>
                <PreviewLocker data={platformDetails} externalId={externalId} config={configLocker} setConfig={setConfigLocker} setSelectDoor={setSelectDoor} selectDoor={selectDoor} doorsInBd={doorsInBd} setOptionSelect={setOptionSelect} isLoadingDoor={isLoadingDoor} setModulesIsCompleted={setModulesIsCompleted} editModeModule={editModeModule} editModeDoors={editModeDoors}/>
            </div>

        </div>
       



    </>
  )
}

export default GroupFormCreate